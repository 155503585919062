// action types
import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  checkOrLoadAuthenticatedUser,
  getIsLoginProcessCompleted,
  getSelectedUserGroup,
  loadUserAppsFromSharedPrefsAndStoreToRedux,
} from './auth';
import {getElapsedRealtime, getLocale, is24HourFormat} from '@smartops/react-native-multiprocess-preferences';
import {
  KEY_BR_SWITCH_SCREEN,
  KEY_BUSINESS_RELEASE,
  KEY_EMERGENCY_MODE,
  KEY_IS_TABLET,
  KEY_LANGUAGE,
  KEY_LAST_LOGIN,
  KEY_LOCKSCREEN_LOCKED,
  KEY_PARENT_APP_VERSION,
  KEY_TRANSLATIONS,
  KEY_TRANSLATIONS_TIMESTAMP,
  KEY_WEB_PATHS,
  loadValue,
  saveValue,
} from 'features/sharedPrefs';
import {logd, logi, logw, sendLogApi} from 'features/logging';
import * as RNFS from 'react-native-fs';
import moment from 'moment';
import {
  loadAppsDetailsFromSharedPrefsAndStoreToRedux,
  loadFavoriteAppsKeysFromSharedPrefsAndStoreToRedux,
} from './apps';
import I18n from 'features/I18n';
import {GET_BR_FOR_GROUP, GET_USER_TRANSLATIONS} from '../config';
import {apiCall} from '../utils/Oauth';
import {getUserSelector} from 'features/selectors';
import {loadKioskApps} from './kiosk';
import {checkFile, handleCdmFiles, readLocalMetadataAndStoreToRedux} from 'features/fileTransfer';
import {readWmsSsoDataAndStoreToRedux, setNewWmsPassword} from 'features/wmsSso';
import {continueLoginAfterBRChangeAction, logoutAttemptAction} from './login';
import {isWeb, showToast} from 'features/platformSpecific';
import {getCurrentScreenSelector as getCurrentScreen, navigationPushAction} from '@smartops/smartops-shared';
import {isBrowserLocale24h} from '../utils/Utils';
import {appVersion} from '../configs/version';
import {loadSettings} from './settings';

// actions
const APP_ACTIVATED = 'APP_ACTIVATED';
const STORE_IS_TABLET = 'STORE_IS_TABLET';
const SET_IS_TABLET = 'SET_IS_TABLET'; // including shared prefs
const STORE_IS_TABLET_DETECTED = 'STORE_IS_TABLET_DETECTED';
const STORE_IS_TABLET_DETECTED_AS_IS_TABLET = 'STORE_IS_TABLET_DETECTED_AS_IS_TABLET';
const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
const STORE__SHOW_EMERGENCY_PROMPT = 'STORE__SHOW_EMERGENCY_PROMPT';
const ENTER_EMERGENCY = 'ENTER_EMERGENCY';
const LEAVE_EMERGENCY = 'LEAVE_EMERGENCY';
const STORE_FOLDER = 'STORE_FOLDER';
const STORE_FILES = 'STORE_FILES';
const RE_DOWNLOAD_FILE = 'RE_DOWNLOAD_FILE';
const RE_DOWNLOAD_FILES = 'RE_DOWNLOAD_FILES';
const SEND_LOG_API = 'SEND_LOG_API';
const STOP_SEND_LOG = 'STOP_SEND_LOG';
const CLEAR_LOG_COUNTERS = 'CLEAR_LOG_COUNTERS';
const CLEAR_FAILED_LOGS = 'CLEAR_FAILED_LOGS';
const LOG_SELECTED_LENGTH = 'LOG_SELECTED_LENGTH';
const LOG_SENT_COUNTER = 'LOG_SENT_COUNTER';
const LOG_SENDING_FAILED = 'LOG_SENDING_FAILED';
const SAVE_24HOUR_FORMAT = 'SAVE_24HOUR_FORMAT';
const SAVE_ANDROID_LOCALE = 'SAVE_ANDROID_LOCALE';
const APP_ONLINE = 'APP_ONLINE';
const STORE_WMS_DATA = 'STORE_WMS_DATA';
const STORE_WMS_METADATA = 'STORE_WMS_METADATA';
const STORE_PASSWORD_DURATION = 'STORE_PASSWORD_DURATION';
const SET_NEW_WMS_PASSWORD = 'SET_NEW_WMS_PASSWORD';
const SET_SHOW_SELECT_GROUP_MODAL = 'SET_SHOW_SELECT_GROUP_MODAL';
const SET_KEYBOARD_IS_OPEN = 'SET_KEYBOARD_IS_OPEN';
const STORE_WEB_PATHS = 'STORE_WEB_PATHS';
const SET_DO_PING_TEST = 'SET_DO_PING_TEST';
const SET_BUSINESS_RELEASE = 'SET_BUSINESS_RELEASE';
const SET_WAS_CHANGE_PIN_MODAL_SHOWN = 'SET_WAS_CHANGE_PIN_MODAL_SHOWN';

const DEFAULT_STATE = {
  appActivationCount: 0,
  emergencyMode: false,
  isTablet: false,
  detectedIsTablet: false,
  loggedIn: false,
  showEmergencyPrompt: false,
  folder: null,
  filesMetadata: {files: []},
  wmsData: null,
  wmsMetadata: null,
  passwordVisibilityDuration: 10,
  is24HourFormat: true,
  androidLocale: 'en',
  isAppOnline: null, // null/true/false
  showSelectGroupModal: false,
  keyboardIsOpen: false,
  webPaths: [],
  availableWebAppKeys: [],
  doPingTest: null, //true/false/null
  businessRelease: null,
  isSendingLog: false,
  wasChangePinModalShown: false,
  logSentCounter: 0,
  logSelectedCounter: 0,
  logSentFailedList: {},
  bottomBannerConfig: {
    isVisible: false,
    message: '',
    callback: () => null,
  },
};

// reducer
export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case APP_ACTIVATED:
      return {...state, appActivationCount: state.appActivationCount + 1};
    case STORE_IS_TABLET_DETECTED:
      return {...state, detectedIsTablet: action.payload};
    case STORE_IS_TABLET_DETECTED_AS_IS_TABLET:
      return {...state, isTablet: action.payload === undefined ? state.detectedIsTablet : action.payload};
    case STORE_IS_TABLET:
      return {...state, isTablet: action.payload};
    case STORE__SHOW_EMERGENCY_PROMPT:
      return {...state, showEmergencyPrompt: action.payload};
    case ENTER_EMERGENCY:
      return {...state, emergencyMode: true};
    case LEAVE_EMERGENCY:
      return {...state, emergencyMode: false};
    case STORE_FOLDER:
      return {...state, folder: action.payload};
    case STORE_FILES:
      return {...state, filesMetadata: action.payload};
    case RE_DOWNLOAD_FILE:
      return {
        ...state,
        filesMetadata: {
          ...state.filesMetadata,
          files: state.filesMetadata.files.map(f =>
            f.id === action.payload
              ? {
                  ...f,
                  isDownloading: true,
                }
              : f,
          ),
        },
      };
    case RE_DOWNLOAD_FILES:
      return {...state, filesMetadata: {...state.filesMetadata, isDownloading: true}};
    case SEND_LOG_API:
      return {...state, isSendingLog: true};
    case LOG_SELECTED_LENGTH:
      return {...state, logSelectedCounter: action.payload};
    case LOG_SENT_COUNTER:
      return {...state, logSentCounter: state.logSentCounter + 1};
    case CLEAR_LOG_COUNTERS:
      return {...state, logSentCounter: 0, logSelectedCounter: 0};
    case LOG_SENDING_FAILED:
      return {
        ...state,
        logSentFailedList: {
          ...state.logSentFailedList,
          ...{[action.payload.logName]: +1 || 1},
        },
      };
    case CLEAR_FAILED_LOGS:
      return {...state, logSentFailedList: {}};
    case STOP_SEND_LOG:
      return {...state, isSendingLog: false};
    case STORE_WMS_DATA:
      return {...state, wmsData: action.payload};
    case STORE_WMS_METADATA:
      return {...state, wmsMetadata: action.payload};
    case STORE_PASSWORD_DURATION:
      return {...state, passwordVisibilityDuration: action.payload};
    case SAVE_24HOUR_FORMAT:
      return {...state, is24HourFormat: action.payload};
    case SAVE_ANDROID_LOCALE:
      return {...state, androidLocale: action.payload};
    case APP_ONLINE:
      return {...state, isAppOnline: action.payload};
    case SET_SHOW_SELECT_GROUP_MODAL:
      return {...state, showSelectGroupModal: action.payload};
    case SET_KEYBOARD_IS_OPEN:
      return {...state, keyboardIsOpen: action.payload};
    case SET_BUSINESS_RELEASE:
      return {...state, businessRelease: action.payload};
    case STORE_WEB_PATHS:
      return {
        ...state,
        webPaths: action.payload,
        availableWebAppKeys: Object.keys(action.payload).map(path => path.replace('_lite_path', '')),
      };
    case SET_DO_PING_TEST:
      return {...state, doPingTest: action.payload};
    case SET_WAS_CHANGE_PIN_MODAL_SHOWN:
      return {...state, wasChangePinModalShown: action.payload};
    default:
      return state;
  }
}

// selectors
export const getIsTabletSelector = state => state.app.isTablet;
export const getAvailableWebAppKeysSelector = state => state.app.availableWebAppKeys;
export const getEmergencyModeSelector = state => state.app.emergencyMode;
export const getFolderSelector = state => state.app.folder;
export const getWmsData = state => state.app.wmsData;
export const getIsAppOnline = state => state.app.isAppOnline;
export const getBusinessRelease = state => state.app.businessRelease;
export const getWebPathsSelector = state => state.app.webPaths;
export const getAndroidLocale = state => state.app.androidLocale;
export const getIs24HourFormat = state => state.app.is24HourFormat;

// action creators
export const appActivatedAction = () => ({type: APP_ACTIVATED});
export const storeIsTabletDetectedAction = payload => ({type: STORE_IS_TABLET_DETECTED, payload});
export const storeIsTabletAction = payload => ({type: STORE_IS_TABLET, payload});
export const setIsTabletAction = payload => ({type: SET_IS_TABLET, payload});
export const setDoPingTestAction = payload => ({type: SET_DO_PING_TEST, payload});
export const storeIsTabletFromUserSettingOrUsingDetectedValueAction = payload => ({
  type: STORE_IS_TABLET_DETECTED_AS_IS_TABLET,
  payload,
});
export const updateLanguageAction = payload => ({type: UPDATE_LANGUAGE, payload});
export const enterEmergencyAction = payload => ({type: ENTER_EMERGENCY, payload});
export const leaveEmergencyAction = payload => ({type: LEAVE_EMERGENCY, payload});
export const storeShowEmergencyPromptAction = payload => ({type: STORE__SHOW_EMERGENCY_PROMPT, payload});
export const storeFolderAction = payload => ({type: STORE_FOLDER, payload});
export const storeFilesAction = payload => ({type: STORE_FILES, payload});
export const reDownloadFileAction = payload => ({type: RE_DOWNLOAD_FILE, payload});
export const reDownloadFilesAction = payload => ({type: RE_DOWNLOAD_FILES, payload});
export const sendLogToApi = payload => ({type: SEND_LOG_API, payload});
export const stopSendLogToApi = payload => ({type: STOP_SEND_LOG, payload});
export const setSelectedLogLengthAction = payload => ({type: LOG_SELECTED_LENGTH, payload});
export const sentLogCounterAddAction = payload => ({type: LOG_SENT_COUNTER, payload});
export const clearLogCountersAction = payload => ({type: CLEAR_LOG_COUNTERS, payload});
export const logSendingFailedAction = payload => ({type: LOG_SENDING_FAILED, payload});
export const clearFailedLogsAction = payload => ({type: CLEAR_FAILED_LOGS, payload});
export const saveIs24HourFormat = payload => ({type: SAVE_24HOUR_FORMAT, payload});
export const saveAndroidLocale = payload => ({type: SAVE_ANDROID_LOCALE, payload});
export const setIsAppOnline = payload => ({type: APP_ONLINE, payload});
export const storeWmsSsoDataAction = payload => ({type: STORE_WMS_DATA, payload});
export const storeWmsSsoMetaDataAction = payload => ({type: STORE_WMS_METADATA, payload});
export const storePasswordDurationAction = payload => ({type: STORE_PASSWORD_DURATION, payload});
export const setNewWmsPasswordAction = payload => ({type: SET_NEW_WMS_PASSWORD, payload});
export const setShowSelectGroupModalAction = payload => ({type: SET_SHOW_SELECT_GROUP_MODAL, payload});
export const setKeyboardIsOpenAction = payload => ({type: SET_KEYBOARD_IS_OPEN, payload});
export const storeWebPaths = (payload, isReload = false) => ({type: STORE_WEB_PATHS, payload, isReload});
export const setBusinessReleaseAction = payload => ({type: SET_BUSINESS_RELEASE, payload});
export const setWasChangePinModalShown = payload => ({type: SET_WAS_CHANGE_PIN_MODAL_SHOWN, payload});

// flows
export function* allAppFlows() {
  yield all([
    takeLatest(APP_ACTIVATED, appActivatedWatcher),
    takeLatest(SET_IS_TABLET, setIsTabletWatcher),
    takeLatest(UPDATE_LANGUAGE, updateLanguageWatcher),
    takeLatest(SET_NEW_WMS_PASSWORD, setNewWmsPassword),
    takeEvery(SEND_LOG_API, sendLogApi),
  ]);
  if (!isWeb) {
    yield all([
      takeLatest(ENTER_EMERGENCY, enterEmergencyMode),
      takeLatest(LEAVE_EMERGENCY, leaveEmergencyMode),
      takeLatest(RE_DOWNLOAD_FILE, reDownloadFileWatcher),
      takeLatest(RE_DOWNLOAD_FILES, reDownloadFilesWatcher),
      call(purgeJobLogs),
    ]);
  } else if (isWeb) {
    yield all([takeLatest(STORE_WEB_PATHS, persistWebPaths)]);
  }
}

async function purgeJobLogs() {
  let jobLogFilePath = RNFS.ExternalDirectoryPath;
  jobLogFilePath = jobLogFilePath.substr(0, jobLogFilePath.lastIndexOf('Android')) + 'Download/';
  //logd(`Purging old files from jobLog dir: ${jobLogFilePath}`);
  // get a list of files and directories in the Download folder
  RNFS.readDir(jobLogFilePath)
    .then(result => {
      //logd('GOT RESULT', result);
      const outdatedJobLogs = result
        //we are only interested in files
        .filter(x => x.isFile())
        //we are only interested in jobLogs
        .filter(x => x.name.startsWith('job-log-'))
        //we are only interested in jobLogs older than 30 days
        .filter(x => moment(x.mtime).isBefore(moment().subtract(30, 'days')));
      logi('Outdated lob log files:', outdatedJobLogs);

      outdatedJobLogs.forEach(element => {
        RNFS.unlink(element.path)
          .then(() => {
            logi('FILE DELETED', element.path);
          })
          .catch(err => {
            logi(err.code, err.message);
          });
      });
    })
    .catch(err => {
      logi(err.message, err.code);
    });
}

export function* updateLanguageWatcher(action) {
  const language = action.payload;
  //logd(`updateLanguageWatcher language: ${language}`);
  if (language) {
    let translations = yield call(loadValue, KEY_TRANSLATIONS(language));
    if (translations) {
      logi(`Updating I18n to use ${language} translations`);
      translations = JSON.parse(translations);
      I18n.i18n.addResourceBundle(language, 'translation', translations.portal, true, true);
      yield call(I18n.i18n.changeLanguage, language);
      return true;
    } else {
      logi(
        `I18n can't be updated to use ${language} translations as values not found in shared prefs under key ${KEY_TRANSLATIONS(
          language,
        )}`,
      );
    }
  } else {
    logw('Can not update I18n as no language was specified');
  }
  return false;
}

export function* downloadTranslationsForUserLanguage() {
  try {
    const user = yield select(getUserSelector);
    if (user && user.language) {
      const userLanguage = user.language;
      logi(`userLanguage: ${userLanguage}`);
      let translationsTimestamp = yield call(loadValue, KEY_TRANSLATIONS_TIMESTAMP(userLanguage));
      translationsTimestamp = translationsTimestamp ? new Date(translationsTimestamp) : new Date(1);
      translationsTimestamp = parseInt((translationsTimestamp.getTime() / 1000).toString(10), 10);
      const headers = {};
      const url = `${GET_USER_TRANSLATIONS}${userLanguage}/timestamp/${translationsTimestamp}`;
      const response = yield call(apiCall, {url, headers, timeout: 60000});
      if (response.metadata && response.metadata.outdated) {
        logi(`Translations data are outdated, saving new response`);
        yield call(saveValue, KEY_TRANSLATIONS(userLanguage), JSON.stringify(response.applications));
        yield call(saveValue, KEY_TRANSLATIONS_TIMESTAMP(userLanguage), response.metadata.time);
        return true;
      } else if (response.metadata && response.metadata.outdated === false) {
        logi(`Translations data are not outdated`);
        return true;
      } else {
        showToast(I18n.t('errors.translationsFailed'));
        logi(`translations data response error`, response);
      }
    } else {
      logw('User or user language not present in Redux store');
    }
  } catch (e) {
    showToast(I18n.t('errors.translationsFailed'));
    logi(e);
  }
  return false;
}

export function* getBusinessReleaseBySelectedGroupId() {
  try {
    const userGroupId = yield select(getSelectedUserGroup);
    const userUrl = window.location.toString();
    const defaultBR = userUrl.substring(userUrl.lastIndexOf('parent') + 7).split('/')[0];
    logi(`BUSINESS_RELEASE userGroupId: ${userGroupId}, userUrl: ${userUrl}, defaultBR: ${defaultBR}`);
    if (defaultBR) {
      // A BR must always exist, so initially we set the default, and it may change later
      yield put(setBusinessReleaseAction(defaultBR));
      yield call(saveValue, KEY_BUSINESS_RELEASE, defaultBR);
    }
    if (userGroupId) {
      const url = GET_BR_FOR_GROUP;
      const options = {
        url,
        method: 'POST',
        timeout: 30000,
        body: JSON.stringify({
          smartops_user_group: userGroupId,
        }),
      };
      const response = yield call(apiCall, options);

      if (response) {
        logi(`BUSINESS_RELEASE response: `, response);
        if (Object.keys(response).length === 0) {
          return;
        }
        const currentBR = response.smartops_version;
        logi(`BUSINESS_RELEASE smartops_version: `, currentBR);
        yield put(setBusinessReleaseAction(currentBR));
        yield call(saveValue, KEY_BUSINESS_RELEASE, currentBR);
        logi(`BUSINESS_RELEASE defaultBR: ${defaultBR} currentBR: ${currentBR}`);
        if (defaultBR !== currentBR) {
          return {
            userUrl,
            currentBR,
          };
        } else {
          return false; // defaultBR and currentBR are the same, so don't attempt BR change
        }
      } else {
        showToast(I18n.t('errors.versionCheckFailed'));
        logi(`BUSINESS_RELEASE error response`, response);
      }
    } else {
      logi('No userGroupId selected');
    }
  } catch (e) {
    showToast(I18n.t('errors.versionCheckFailed'));
    logi(e);
  }
}

export function* getPersistedUserLanguage() {
  const language = yield call(loadValue, KEY_LANGUAGE);
  logi(`getPersistedUserLanguage language: `, language);
  return language || 'en';
}

function* checkDeviceRestarted() {
  return yield call(checkDeviceRestartedAsync);
}

export async function checkDeviceRestartedAsync() {
  const lastLoginString = await loadValue(KEY_LAST_LOGIN); //can be null
  if (lastLoginString === null || lastLoginString === undefined) {
    return true;
  }
  const lastLogin = new Date(lastLoginString);
  const millisSinceBoot = await getElapsedRealtime();
  const startupTime = new Date(new Date().getTime() - Number(millisSinceBoot));
  const resBool = startupTime.getTime() > lastLogin.getTime();
  logi(`LCDDBG checkDeviceRestartedAsync resBool: ${resBool}`);
  return resBool;
}

function* isDeviceLocked() {
  const lockedString = yield call(loadValue, KEY_LOCKSCREEN_LOCKED); //can be null
  if (lockedString === null || lockedString === undefined) {
    return true;
  }
  return lockedString === 'true';
}

export function* loadDataFromSharedPrefsWhenLoginIsSkipped() {
  // some data is already loaded by loadUserAndTokensFromSharedPrefs when checking if user is logged already
  // load user apps
  yield call(loadUserAppsFromSharedPrefsAndStoreToRedux);
  // load apps details
  yield call(loadAppsDetailsFromSharedPrefsAndStoreToRedux);
  // load favorite apps
  yield call(loadFavoriteAppsKeysFromSharedPrefsAndStoreToRedux);
  // load web paths for lite
  if (isWeb) {
    yield call(loadWebPathsFromSharedPrefsAndStoreToRedux);
  }
}

function* loadWebPathsFromSharedPrefsAndStoreToRedux() {
  const webPaths = JSON.parse((yield call(loadValue, KEY_WEB_PATHS)) || '[]');
  yield put(storeWebPaths(webPaths));
}

function* appActivatedWatcher() {
  logi('com.msw_parent was activated');
  const isUserLogged = yield call(checkOrLoadAuthenticatedUser);
  logi(`User ${isUserLogged ? 'IS' : 'IS NOT'} logged in`);
  const isLocked = yield call(isDeviceLocked);
  logi(`Device ${isLocked ? 'IS' : 'IS NOT'} locked`);
  if (isWeb) {
    const brSwitchScreen = yield call(loadValue, KEY_BR_SWITCH_SCREEN);
    logd(`brSwitchScreen: ${brSwitchScreen}`);
    // Continue login only when we are in Login or Group Management screen
    if (brSwitchScreen != null && ['splashScreen', 'pageLogin', 'userGroupManagementScreen'].includes(brSwitchScreen)) {
      // The userGroupManagementScreen has a 'back' button, but after a BR switch, we don't have the stack, so we are going to main page
      const goToScreen =
        brSwitchScreen === 'pageLogin' || brSwitchScreen === 'splashScreen' ? brSwitchScreen : 'pageMain';
      yield put(navigationPushAction(goToScreen, {clearFields: false, isLoading: true}));
      //console.log(`SO-5790 continueLoginAfterBRChangeAction ${brSwitchScreen === 'userGroupManagementScreen'}`);
      yield put(continueLoginAfterBRChangeAction(brSwitchScreen === 'userGroupManagementScreen')); //true if it is reInit
      return;
    }
  }
  const wasDeviceRestarted = isWeb ? false : yield call(checkDeviceRestarted);
  logi(`Device ${wasDeviceRestarted ? 'WAS' : 'WAS NOT'} restarted`);
  const emergencyMode = yield select(getEmergencyModeSelector);
  logi(`Device ${emergencyMode ? 'RUNS' : 'DOES NOT RUN'} in emergency mode`);
  const is24HourFormatVar = isWeb ? isBrowserLocale24h() : yield is24HourFormat();
  const locale = isWeb ? window.navigator.language || 'en' : yield getLocale();
  if (typeof is24HourFormatVar !== 'boolean') logw(`is24HourFormatVar is not a boolean!!`);
  yield put(saveIs24HourFormat(is24HourFormatVar));
  yield put(saveAndroidLocale(locale));
  logi(`is24HourFormatVar: ${is24HourFormatVar}, locale: ${locale}`);
  const isLoginProcessCompleted = yield call(getIsLoginProcessCompleted);
  logi(`isLoginProcessCompleted: ${isLoginProcessCompleted} emergencyMode: ${emergencyMode}`);
  yield call(loadSettings);
  if (isUserLogged && !wasDeviceRestarted && isLoginProcessCompleted) {
    logi(
      `isUserLogged && !wasDeviceRestarted && isLoginProcessCompleted: ${
        isUserLogged && !wasDeviceRestarted && isLoginProcessCompleted
      }`,
    );
    yield call(loadDataFromSharedPrefsWhenLoginIsSkipped);
    yield call(loadKioskApps);
    //Fix for translations missing after killing app SO-1512
    const user = yield select(getUserSelector);
    yield call(readLocalMetadataAndStoreToRedux);
    yield call(readWmsSsoDataAndStoreToRedux);
    yield put(updateLanguageAction(user.language));
    yield call(saveValue, KEY_PARENT_APP_VERSION, appVersion);
    if ((yield select(getCurrentScreen)) === 'splashScreen') {
      yield put(navigationPushAction('pageMain'));
    }
  } else if (isLoginProcessCompleted || wasDeviceRestarted || emergencyMode) {
    logi(`appActivatedWatcher else if (isLoginProcessCompleted)...`);
    yield call(loadKioskApps);
    if (emergencyMode) {
      if ((yield select(getCurrentScreen)) === 'splashScreen') {
        yield put(navigationPushAction('pageMain'));
      }
    } else if (
      (yield select(getCurrentScreen)) !== 'privacyNotificationScreen' &&
      (yield select(getCurrentScreen)) !== 'selectUserGroupScreen'
    ) {
      logi(`appActivatedWatcher logoutAttemptAction...`);
      yield put(logoutAttemptAction());
    }
  } else if (!isLoginProcessCompleted && !emergencyMode) {
    //SO-4159 logout when user is logged in but login process is not completed
    yield put(logoutAttemptAction());
  } else {
    logi(
      `isUserLogged: ${isUserLogged}, isLoginProcessCompleted: ${isLoginProcessCompleted}, wasDeviceRestarted: ${wasDeviceRestarted}, isLocked: ${isLocked}`,
    );
  }
}

function* setIsTabletWatcher(action) {
  yield put(storeIsTabletAction(action.payload));
  yield call(saveIsTabletFromReduxToSharedPrefs);
}

export function* saveIsTabletFromReduxToSharedPrefs() {
  const isTablet = yield select(getIsTabletSelector);
  yield call(saveValue, KEY_IS_TABLET, isTablet.toString());
}

function* persistWebPaths({payload, isReload}) {
  if (!isReload) {
    yield call(saveValue, KEY_WEB_PATHS, JSON.stringify(payload));
  }
}

function* enterEmergencyMode() {
  yield put(storeShowEmergencyPromptAction(false));
  yield call(saveValue, KEY_EMERGENCY_MODE, 'true');
  yield put(navigationPushAction('pageMain'));
}

function* leaveEmergencyMode() {
  yield call(saveValue, KEY_EMERGENCY_MODE, 'false');
}

function* reDownloadFileWatcher(action) {
  const user = yield select(getUserSelector);
  const activeGroup = yield select(getSelectedUserGroup);
  yield call(checkFile, user.login, activeGroup != null ? [parseInt(activeGroup, 10)] : [], action.payload);
}

function* reDownloadFilesWatcher() {
  const user = yield select(getUserSelector);
  const activeGroup = yield select(getSelectedUserGroup);
  yield call(handleCdmFiles, user.login, activeGroup != null ? [parseInt(activeGroup, 10)] : []);
}
