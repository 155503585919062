import React, {Component} from 'react';
import {AboutScreen as AboutScreenComponent, navigationPushAction} from '@smartops/smartops-shared';
import {getAvailableLogs} from '@smartops/platform-shared';
import {connect} from 'react-redux';
import {getReleaseDate} from '../config';
import DeviceInfo from 'react-native-device-info';
import {getIMEI} from '@smartops/react-native-multiprocess-preferences';
import I18n from 'features/I18n';
import {isQa, isWeb} from 'features/platformSpecific';
import {appVersion, buildDateTime} from '../configs/version';

class AboutScreen extends Component {
  state = {
    releaseDate: !isWeb ? getReleaseDate() : buildDateTime,
    version: !isWeb ? DeviceInfo.getVersion() : isQa() ? `${appVersion}-qa` : appVersion,
    appName: I18n.t('about.app'),
    deviceName: undefined,
    imei: 'N/A',
    macAddress: undefined,
    androidVersion: !isWeb && DeviceInfo.getSystemVersion(),
    androidAPILevel: undefined,
    showLogSection: !!this.props.login,
    logCounter: 0, //placeholder
    // Labels
    namePlaceholder: I18n.t('about.appName'),
    userPlaceholder: I18n.t('about.user'),
    appPlaceholder: I18n.t('about.applicationSection'),
    versionPlaceholder: I18n.t('about.version'),
    releaseDatePlaceholder: I18n.t('about.releaseDate'),
    devicePlaceholder: I18n.t('about.deviceSection'),
    deviceNamePlaceholder: I18n.t('about.deviceName'),
    androidVersionPlaceholder: I18n.t('about.androidVersion'),
    androidAPILevelPlaceholder: I18n.t('about.androidApiLevel'),
    model: '',
    logTitle: I18n.t('log.log'),
    logText: I18n.t('log.logsAvailable'),
    logBtnText: I18n.t('log.logDetails'),
    logDetail: () => this.props.navigationPushAction('logScreen'),
  };

  componentDidMount = () => {
    if (!isWeb) {
      getIMEI().then(result => {
        if (result.length !== 0 && result[0] != null) {
          this.setState({imei: result.join(', ')});
        }
      });
      //https://stackoverflow.com/a/11705949 MAC Address is 02:00:00:00:00:00 if WIFI is OFF.
      DeviceInfo.getMacAddress().then(result => result !== '02:00:00:00:00:00' && this.setState({macAddress: result}));
      DeviceInfo.getApiLevel().then(res => this.setState({androidAPILevel: res}));
      let deviceName = '';
      const model = DeviceInfo.getModel();
      this.setState({model: model});
      DeviceInfo.getManufacturer().then(res => {
        deviceName = res + ' ' + this.state.model;
        this.setState({deviceName: deviceName});
      });
    }
    getAvailableLogs().then(logs => this.setState({logCounter: logs.filter(log => log.totalSize > 0).length}));
  };

  render() {
    return (
      <AboutScreenComponent
        {...this.props}
        data={{
          ...this.state,
          login: this.props.login,
          firstName: this.props.firstName,
          lastName: this.props.lastName,
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.auth.user.login,
    firstName: state.auth.user.firstName,
    lastName: state.auth.user.lastName,
  };
}

export default connect(mapStateToProps, {navigationPushAction})(AboutScreen);
