import {call} from 'redux-saga/effects';
import {OAUTH_SECRET_PASSWORD, OAUTH_SECRET_USERNAME, REQUEST_TIMEOUT} from '../config';
import Base64 from '../utils/Base64';
import {
  REST_RESPONSE_BAD_REQUEST,
  REST_RESPONSE_BAD_RESPONSE,
  REST_RESPONSE_CONFLICT,
  REST_RESPONSE_CONNECTION_ERROR,
  REST_RESPONSE_NOT_FOUND,
  REST_RESPONSE_OK,
  REST_RESPONSE_PRECONDITION_FAILED,
  REST_RESPONSE_PRECONDITION_REQUIRED,
  REST_RESPONSE_SERVER_ERROR,
  REST_RESPONSE_UNAUTHORIZED,
} from '../actions/types';
import {doFetch} from '../utils/fetch';
import {logi, logw} from '../features/logging';

export function isServerError(responseResult) {
  return [REST_RESPONSE_NOT_FOUND, REST_RESPONSE_SERVER_ERROR, REST_RESPONSE_BAD_RESPONSE].includes(responseResult);
}

export function* postParamsWithJSONResponseToUM(url, params) {
  const encodedParams = encodeParameters(params);
  let response;
  try {
    response = yield call(postDataToUMWithTimeout, url, encodedParams);
  } catch (error) {
    //logd(`postParamsWithJSONResponseToUM catch error`, error);
    return handleFetchException(error);
  }
  return yield call(processPostResponse, response);
}

export function* postDataToUMWithTimeout(url, formBody) {
  logi('postDataToUMWithTimeout URL: ' + url);
  try {
    // timeout is already in doFetch
    const posts = yield postDataToUM(url, formBody);
    if (posts) {
      return posts;
    }
  } catch (e) {
    logw('Fetch error', e);
    throw new Error('Connection timeout, make sure you are connected to the network.');
  }
}

export async function postDataToUM(url, formBody) {
  return doFetch(url, {
    timeout: REQUEST_TIMEOUT,
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + Base64.btoa(OAUTH_SECRET_USERNAME + ':' + OAUTH_SECRET_PASSWORD),
    },
    body: formBody,
  });
}

function encodeParameters(params) {
  let formBody = [];
  for (const property in params) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + '=' + encodedValue);
  }
  formBody = formBody.join('&');
  return formBody;
}

export function* processPostResponse(response) {
  if (!response.status) {
    return;
  }
  if (response.status >= 300) {
    logi('processPostResponse response: ', response);
  }
  if (response.status >= 200 && response.status <= 299) {
    return yield call(createLogicalResponse, REST_RESPONSE_OK, response);
  } else if (response.status === 400) {
    return yield call(createLogicalResponse, REST_RESPONSE_BAD_REQUEST, response);
  } else if (response.status === 401) {
    return yield call(createLogicalResponse, REST_RESPONSE_UNAUTHORIZED, response);
  } else if (response.status === 404) {
    return yield call(createLogicalResponse, REST_RESPONSE_NOT_FOUND, response);
  } else if (response.status === 409) {
    return yield call(createLogicalResponse, REST_RESPONSE_CONFLICT, response);
  } else if (response.status === 412) {
    return yield call(createLogicalResponse, REST_RESPONSE_PRECONDITION_FAILED, response);
  } else if (response.status === 428) {
    return yield call(createLogicalResponse, REST_RESPONSE_PRECONDITION_REQUIRED, response);
  } else if (response.status >= 500) {
    return yield call(createLogicalResponse, REST_RESPONSE_SERVER_ERROR, response);
  } else {
    return yield call(createLogicalResponse, REST_RESPONSE_NOT_FOUND, null);
  }
}

export function handleFetchException(error) {
  return {result: REST_RESPONSE_CONNECTION_ERROR, body: error.message};
}

function* createLogicalResponse(resultCode, response) {
  const body = response ? yield call(parseJson, response) : {};
  const lastModified = response?.headers?.map ? response.headers.map['last-modified'] : undefined;
  return {result: resultCode, body: body, lastModified: lastModified};
}

function* parseJson(response) {
  try {
    return yield call([response, 'json']);
  } catch (error) {
    return {};
  }
}
