import Metrics from './metrics';
import {getCountriesByGroupAndApp, getFacilitiesByGroupAndApp} from './attrValues';
import {KIOSK_FOLDER} from '../config';
import {isWeb, showToast} from './platformSpecific';
import I18n from './I18n';
import {logd, logi, logw} from './logging';
import {internetConnectionCheck} from './appStartValidation';
import {ALL_APPS, getRunningAppsKeys} from '../flows/apps';
import {loadValue} from './sharedPrefs';
import {openLiteApp} from '../utils/UtilsTS';
import {Linking, NativeModules, StyleProp, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import * as SendIntentAndroid from 'react-native-send-intent';
import {forUnlocked} from './lockUtils';
import {createWmsFilesystemFileAction, SMART_SCAN, VELOCITY_SCAN} from './wmsSso';
import {getEmergencyModeSelector, getFolderSelector, getWebPathsSelector, storeFolderAction} from '../flows/app';
// @ts-ignore
import {startApp} from '@smartops/react-native-multiprocess-preferences';
import {useDispatch, useSelector} from 'react-redux';
import {getKioskConfigAvailableSelector, getKioskWarningMessageSelector} from '../flows/kiosk';
import {App} from '../types';
import React, {PropsWithChildren, useState} from 'react';
import {dhlBlack, dhlRed, dhlStatusGreen, dhlWhiteBasic, doubleClickSafe} from '@smartops/smartops-shared';
import {Launch} from '../utils/types';
import Modal from 'react-native-modal';

type AppOpenerProps = {
  app?: App;
  launch?: Launch | undefined;
  style?: StyleProp<any>;
  onPress?: () => void;
  testId?: string;
};

const SafeTouchableOpacity = doubleClickSafe(TouchableOpacity);

const AppOpener = (props: PropsWithChildren<AppOpenerProps>) => {
  const [isActivityWarningModalVisible, setIsActivityWarningModalVisible] = useState<boolean>(false);
  const [appToOpen, setAppToOpen] = useState<any>({});
  const isKioskConfigAvailable: boolean | undefined = useSelector(getKioskConfigAvailableSelector);
  const kioskWarningMessage: string | undefined = useSelector(getKioskWarningMessageSelector);
  const webPaths: string[] | undefined = useSelector(getWebPathsSelector);
  const emergencyMode = useSelector(getEmergencyModeSelector);
  const runningAppsKeys = useSelector(getRunningAppsKeys);
  const folder = useSelector(getFolderSelector);
  const isKiosk = emergencyMode || folder === KIOSK_FOLDER;
  const dispatch = useDispatch();

  const openUrl = async (
    app: any,
    modalProps: {
      ignoreLaunchJson: boolean;
      isActivityWarningModalSubmitted: boolean;
    } = {ignoreLaunchJson: false, isActivityWarningModalSubmitted: false},
  ) => {
    setAppToOpen(app);
    const userGroupId = await Metrics.getUserGroupId();
    const country = await getCountriesByGroupAndApp(userGroupId || '-1', app.key);
    const facility = await getFacilitiesByGroupAndApp(userGroupId || '-1', app.key);
    const launchAsJson: string | undefined = modalProps.ignoreLaunchJson ? '{}' : JSON.stringify(props.launch);
    if (app.url === 'Home') {
      if (isKioskConfigAvailable) {
        dispatch(storeFolderAction(KIOSK_FOLDER));
      } else {
        showToast(I18n.t(kioskWarningMessage || 'errors.kioskConfigMissing'));
      }
    } else {
      if (app.webApp) {
        if (!isWeb) {
          logi('OpenApp2 - openUrl::59');
          await Metrics.trackEvent(isKiosk ? Metrics.EventType.OpenKioskApp : Metrics.EventType.OpenApp, {
            app_label: app.name,
            usergroup_id: userGroupId,
            ...(!!country && country.length !== 0 && {country: country.toString()}),
            ...(!!facility && facility.length !== 0 && {facility: facility.toString()}),
          });
        }
        if (isWeb) {
          showToast(I18n.t('errors.noPermissionOnApp'));
        } else {
          const kioskBrowser = 'kiosk_browser://main/';
          logi(`opening webapp`, app.name, app.url);
          startApp(kioskBrowser, {urlToOpen: app.url, title: app.name, launch: launchAsJson});
        }
      } else {
        logi(`Opening ${app.url}, app.key: ${app.key}`);
        const isInternetAvailable = await internetConnectionCheck(app.key);
        if (!isInternetAvailable) {
          showToast(I18n.t('errors.appStartMissingConnection'));
          return;
        }

        const validationMessageKey = ALL_APPS.find(a => a.label === app.key)?.validationMessageKey;
        const errorMessage = validationMessageKey == null ? null : await loadValue(validationMessageKey);
        if (errorMessage != null) {
          showToast(errorMessage);
          return;
        }
        await createWmsFile(app.key, app.url);
        if (isWeb) {
          await trackOpenAppEvent(app, userGroupId, country, facility);
          await openLiteApp(app.key, webPaths ? webPaths : []);
        } else {
          logd(`Opening android app.....`);
          Linking.canOpenURL(app.url)
            .then(supported => {
              logi('List of apps with running activities: ' + runningAppsKeys);
              const launchScreenLabel = launchAsJson && JSON.parse(launchAsJson).screen_label;
              const isAnyActivityRunningInChildAppToOpen = runningAppsKeys && runningAppsKeys.includes(app.key);
              if (
                launchScreenLabel &&
                isAnyActivityRunningInChildAppToOpen &&
                !modalProps.isActivityWarningModalSubmitted
              ) {
                logi('Now its on user to decide if ignore screen label or not during app opening..');
                setIsActivityWarningModalVisible(true);
                return;
              }
              if (!supported) {
                logi(`Linking can't handle url: ${app.url}`);
                logi('Trying with SendIntentAndroid...');
                SendIntentAndroid.openApp(app.url, {launch: launchAsJson}).then(wasOpened => {
                  if (!wasOpened) {
                    showToast(I18n.t('errors.appNotInstalled', {appName: app.name}));
                  } else {
                    trackOpenAppEvent(app, userGroupId, country, facility);
                  }
                });
              } else {
                logi(`Else opening`, app.url === app.url.toLowerCase());
                trackOpenAppEvent(app, userGroupId, country, facility);
                /// todo is enaough !app.url.includes('/main/')
                if (app.url === app.url.toLowerCase() && !app.url.includes('/main/')) {
                  return Linking.openURL(app.url);
                } else {
                  return startApp(app.url, {launch: launchAsJson});
                }
              }
            })
            .catch(err => logw('An error occurred', err));
        }
      }
    }
  };

  const trackOpenAppEvent = async (app: any, userGroupId: any, country: any, facility: any) => {
    logi('OpenApp3 - trackOpenAppEvent::125');
    await Metrics.trackEvent(isKiosk ? Metrics.EventType.OpenKioskApp : Metrics.EventType.OpenApp, {
      app_label: isKiosk ? app.name : app.key,
      usergroup_id: userGroupId,
      ...(!!country && country.length !== 0 && {country: country.toString()}),
      ...(!!facility && facility.length !== 0 && {facility: facility.toString()}),
    }).catch(e => 'Error during track open event: ' + JSON.stringify(e));
  };

  const openUrlWithLockCheck = isWeb ? openUrl : async (app: App) => forUnlocked(() => openUrl(app), 'childAppStart');

  return (
    <SafeTouchableOpacity
      testID={props.testId}
      style={props.style}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        } else if (props.app) {
          void openUrlWithLockCheck(props.app);
        } else if (props.app === undefined) {
          logi(
            `App to be opened is undefined, launchName: ${props.launch?.name}, launchAppName: ${props?.launch?.app_label}`,
          );
          showToast(I18n.t('errors.noPermissionOnApp'));
        }
        return {};
      }}
      accessibilityLabel={props.app ? props.app.key : undefined}>
      {props.children}
      <Modal isVisible={isActivityWarningModalVisible} style={{flex: 1}}>
        <View style={styles.warningModalContainer}>
          <Text style={styles.warningModalTitle}>{I18n.t('dataWillBeLostModal.title')}</Text>
          <Text style={styles.warningModalText}>{I18n.t('dataWillBeLostModal.description')}</Text>
          <View style={{justifyContent: 'flex-end', flex: 1}}>
            <View style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
              <TouchableOpacity
                style={styles.warningModalButton}
                onPress={() => {
                  logi('Call complete function');
                  setIsActivityWarningModalVisible(false);
                  openUrl(appToOpen, {
                    isActivityWarningModalSubmitted: true,
                    ignoreLaunchJson: false,
                  });
                }}>
                <Text style={styles.warningModalButtonText}>{I18n.t('common.complete')}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.warningModalButton, {backgroundColor: dhlRed}]}
                onPress={() => {
                  logi('Call "new" function');
                  setIsActivityWarningModalVisible(false);
                  openUrl(appToOpen, {isActivityWarningModalSubmitted: true, ignoreLaunchJson: true});
                }}>
                <Text style={styles.warningModalButtonText}>{I18n.t('common.new')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </SafeTouchableOpacity>
  );
};

const createWmsFile = async (appKey: string, uri: string | undefined) => {
  if (appKey === SMART_SCAN || appKey === VELOCITY_SCAN) {
    const isAppAvailable = await NativeModules.ParentNativeTools.appInstalledOrNot(uri);
    logi(`createWmsFile isAppAvailable: ${isAppAvailable}, uri: ${uri}`);
    if (isAppAvailable) {
      createWmsFilesystemFileAction(appKey === SMART_SCAN ? 'smart_scanner' : 'wms_scanner');
    }
  }
};

export default AppOpener;

const styles = StyleSheet.create({
  warningModalContainer: {
    backgroundColor: dhlWhiteBasic,
    width: '87%',
    height: '70%',
    alignSelf: 'center',
    borderRadius: 10,
  },
  warningModalTitle: {
    fontSize: 23,
    color: dhlBlack,
    fontWeight: 'bold',
    paddingTop: 24,
    textAlign: 'center',
  },
  warningModalButton: {
    margin: 5,
    height: 50,
    width: 100,
    backgroundColor: dhlStatusGreen,
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningModalText: {
    color: dhlBlack,
    padding: 15,
    fontSize: 17,
  },
  warningModalButtonText: {
    fontSize: 16,
    fontWeight: '500',
    color: dhlWhiteBasic,
  },
});
