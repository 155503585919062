import {all, call, put, select, takeLeading} from 'redux-saga/effects';

import {
  NotificationGroupData,
  ApplicationNotificationAction,
  ApplicationNotificationData,
  ApplicationNotificationsState,
  NotificationData,
  NotificationDataApiResponse,
  NotificationFilter,
  NotificationSeverity,
  UserApplications,
} from '../utils/types';
import {getAppsDetailsSelector, getAppSettingsValue} from './apps';
import {isNaN, isString} from 'lodash';
import {logd, logw} from '../features/logging';
import {ROOT_DHLLINK, TOKEN_REFRESH_FAILED_BROADCAST} from '../config';
import {apiCall} from '../utils/Oauth';
import {extractIdsOfGivenTypeFromDl} from '../utils/Utils';
import {getUserAppsSelector, getUserSelector} from '../features/selectors';
import {getLogicsEmployeeIdSelector, getSelectedUserGroup, getUserLoginSelector} from './auth';
import {KEY_SELECTED_USERGROUP_ID, loadValue} from '../features/sharedPrefs';
import appIndApr from '../features/apps/indapr';
import {getIsAppOnline} from './app';
import {getSettingsSelector, ParentSettings} from './settings';
import {App} from '../types';
import I18n from '../features/I18n';
import {logoutAttemptAction} from './login';
import {isWeb} from '../features/platformSpecific';
import {DeviceEventEmitter} from 'react-native';

// actions
const SET_NOTIFICATION_ITEMS = 'SET_NOTIFICATION_ITEMS';
const SET_BASIC_SETTINGS = 'SET_BASIC_SETTINGS';
const SET_SEVERITIES = 'SET_SEVERITIES';
const SET_NOTIFICATION_GROUP = 'SET_NOTIFICATION_GROUP';
const SET_NOTIFICATION_LOADING = 'SET_NOTIFICATION_LOADING';
const SET_NOTIFICATION_FILTER = 'SET_NOTIFICATION_FILTER';
const RESET_STATE_VALID_UNTIL = 'RESET_STATE_VALID_UNTIL';
const RELOAD_NOTIFICATIONS = 'RELOAD_NOTIFICATIONS';
const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';
const CLEAR_NOTIFICATION_SETTINGS = 'CLEAR_NOTIFICATION_SETTINGS';
const SET_IS_NOTIFICATIONS_LIST_OPEN = 'SET_IS_NOTIFICATIONS_LIST_OPEN';
const RESET_IS_NOTIFICATIONS_LIST_OPEN = 'RESET_IS_NOTIFICATIONS_LIST_OPEN';

// constants
const JSON_HEADER = {'Content-Type': 'application/json'};
const DEFAULT_CACHE_VALIDITY_IN_SECONDS = 10;
const DEFAULT_GROUP_TITLE_LIMIT = 20;
const DEFAULT_FILTER_MAX_ROWS = 3;
const DEFAULT_LIST_TIMEOUT_IN_SECONDS = 30;
const DEFAULT_INIT_TIMEOUT_IN_SECONDS = 30;
const DEFAULT_VISIBLE_TO_EVERYONE = false;

const DEFAULT_STATE_OF_NOTIFICATIONS: ApplicationNotificationData = {
  stateValidUntilAt: undefined,
  cacheValidityInSeconds: DEFAULT_CACHE_VALIDITY_IN_SECONDS,
  notificationGroups: [],
  isNotificationsListOpen: false,
  filter: undefined,
};

const DEFAULT_SETTINGS_STATE = {
  notificationSeverities: [],
  groupTitleLimit: DEFAULT_GROUP_TITLE_LIMIT,
  filterMaxRows: DEFAULT_FILTER_MAX_ROWS,
  listTimeout: DEFAULT_LIST_TIMEOUT_IN_SECONDS,
  initTimeout: DEFAULT_INIT_TIMEOUT_IN_SECONDS,
  visibleToEveryone: DEFAULT_VISIBLE_TO_EVERYONE,
};

const initialState: ApplicationNotificationsState = {
  settings: DEFAULT_SETTINGS_STATE,
  data: DEFAULT_STATE_OF_NOTIFICATIONS,
};

// reducer
export const applicationNotificationReducer = (state = initialState, action: ApplicationNotificationAction) => {
  let copiedGroups: NotificationGroupData[];
  switch (action.type) {
    case SET_SEVERITIES: {
      return {
        ...state,
        settings: {
          ...state.settings,
          notificationSeverities: action.payload.settings?.notificationSeverities,
        },
      };
    }
    case SET_BASIC_SETTINGS:
      return {
        ...state,
        data: {
          ...state.data,
          cacheValidityInSeconds: action.payload.settings?.cacheValidityInSeconds,
        },
        settings: {
          groupTitleLimit: action.payload.settings?.groupTitleLimit,
          filterMaxRows: action.payload.settings?.filterMaxRows,
          listTimeout: action.payload.settings?.listTimeout,
          initTimeout: action.payload.settings?.initTimeout,
          visibleToEveryone: action.payload.settings?.visibleToEveryone,
        },
      };
    case SET_NOTIFICATION_LOADING: {
      const key = action.payload.notification!.appKey;
      copiedGroups = state.data.notificationGroups;
      copiedGroups.filter(it => it.appKey === key).forEach(it => (it.loading = true));
      return {...state, data: {...state.data, notificationGroups: copiedGroups}};
    }
    case SET_NOTIFICATION_GROUP: {
      const appKey = action.payload.notification!.appKey;
      const index = state.data.notificationGroups.findIndex(it => it.appKey === appKey);
      copiedGroups = state.data.notificationGroups;
      if (index !== -1) {
        action.payload.notification!.settings = copiedGroups[index].settings;
        action.payload.notification!.isNotificationsListOpen = copiedGroups[index].isNotificationsListOpen;
        copiedGroups.splice(index, 1, action.payload.notification!);
        logd('Resetting existing notification by key: ' + action.payload.notification?.appKey);
      } else {
        logd('Setting notification by key: ' + action.payload.notification?.appKey);
        copiedGroups.push(action.payload.notification!);
      }
      return {...state, data: {...state.data, notificationGroups: copiedGroups}};
    }
    case SET_NOTIFICATION_ITEMS: {
      const notifications = action.payload.items!;
      copiedGroups = state.data.notificationGroups;
      notifications.forEach(item => {
        const group = copiedGroups.find(it => it.appKey === item.type);
        if (group) {
          /// remove origin one first if exists
          group.notifications = group.notifications.filter(it => item.id !== it.id);
          group.notifications.push(item);
          group.countOfNotifications = group.notifications.length;
        }
      });
      copiedGroups?.forEach(it => (it.loading = false));
      return {...state, data: {...state.data, notificationGroups: copiedGroups}};
    }
    case SET_IS_NOTIFICATIONS_LIST_OPEN: {
      copiedGroups = state.data.notificationGroups || [];
      const group = copiedGroups.find(it => it.appKey === action.payload.appKey);
      if (group) group.isNotificationsListOpen = action.payload.isNotificationsListOpen === true;
      return {...state, data: {...state.data, notificationGroups: copiedGroups}};
    }
    case SET_NOTIFICATION_FILTER: {
      return {...state, data: {...state.data, filter: action.payload.filter}};
    }
    case RESET_IS_NOTIFICATIONS_LIST_OPEN: {
      copiedGroups = state.data.notificationGroups || [];
      copiedGroups.forEach(it => (it.isNotificationsListOpen = false));
      return {...state, data: {...state.data, notificationGroups: copiedGroups}};
    }
    case RESET_STATE_VALID_UNTIL: {
      const notFetchFromApiUntilAt = new Date(Date.now() + state.data.cacheValidityInSeconds * 1000);
      return {...state, data: {...state.data, stateValidUntilAt: notFetchFromApiUntilAt}};
    }
    case CLEAR_NOTIFICATIONS:
      return {
        ...state,
        data: {
          stateValidUntilAt: undefined,
          cacheValidityInSeconds: DEFAULT_CACHE_VALIDITY_IN_SECONDS,
          notificationGroups: [],
          filter: undefined,
        },
      };
    case CLEAR_NOTIFICATION_SETTINGS:
      return {
        ...state,
        settings: {
          notificationSeverities: [],
          groupTitleLimit: DEFAULT_GROUP_TITLE_LIMIT,
          filterMaxRows: DEFAULT_FILTER_MAX_ROWS,
          listTimeout: DEFAULT_LIST_TIMEOUT_IN_SECONDS,
          initTimeout: DEFAULT_INIT_TIMEOUT_IN_SECONDS,
          visibleToEveryone: DEFAULT_VISIBLE_TO_EVERYONE,
        },
      };
    default:
      return state;
  }
};

/// flows
export default function* applicationNotificationFlows() {
  yield takeLeading(RELOAD_NOTIFICATIONS, function* () {
    yield loadApplicationsNotifications(false);
  });
}

/// functions
export function* loadNotificationSettings() {
  const cacheValidity: number = yield getNumSetting(
    'notification_panel.cache_validity',
    DEFAULT_CACHE_VALIDITY_IN_SECONDS,
  );
  const groupTitleLimit: number = yield getNumSetting(
    'notifications.notification_group_title_limit',
    DEFAULT_GROUP_TITLE_LIMIT,
  );
  const filterMaxRows: number = yield getNumSetting('notifications.filter_max_rows', DEFAULT_FILTER_MAX_ROWS);
  const initTimeout: number = yield getNumSetting('notifications_init.timeout', DEFAULT_LIST_TIMEOUT_IN_SECONDS);
  const listTimeout: number = yield getNumSetting('notifications_list.timeout', DEFAULT_INIT_TIMEOUT_IN_SECONDS);
  const visibleEveryone: boolean = yield getBoolSetting(
    'notifications.visible_to_everyone',
    DEFAULT_VISIBLE_TO_EVERYONE,
  );

  yield put({
    type: SET_BASIC_SETTINGS,
    payload: {
      settings: {
        cacheValidityInSeconds: cacheValidity,
        groupTitleLimit: groupTitleLimit,
        filterMaxRows: filterMaxRows,
        listTimeout: listTimeout,
        initTimeout: initTimeout,
        visibleToEveryone: visibleEveryone,
      },
    },
  });
}

function* getNumSetting(key: string, defaultValue: number): Generator<any, number, any> {
  try {
    const valueFromSettings: string | undefined = yield call(getAppSettingsValue, key);
    const isNumber = valueFromSettings && !isNaN(valueFromSettings);
    const value: number = isNumber ? Number.parseInt(valueFromSettings, undefined) : defaultValue;
    logd(`Setting '${key}' with value: ${value}`);
    return value;
  } catch (e: any) {
    logd(`Failed setting '${key}', leaving default ${defaultValue}, ex: ${e}`);
    return defaultValue;
  }
}

function* getBoolSetting(key: string, defaultValue: boolean): Generator<any, boolean, any> {
  try {
    const valueFromSettings: string | undefined = yield call(getAppSettingsValue, key);
    const isTrue = (valueFromSettings && 'true' === valueFromSettings) === true;
    logd(`Setting '${key}' with value: ${isTrue}`);
    return isTrue;
  } catch (e: any) {
    logd(`Failed setting '${key}', leaving default ${defaultValue}, ex: ${e}`);
    return defaultValue;
  }
}

function isDisplayable(key: string, settings: ParentSettings, appsDetails: App[] | undefined): boolean {
  const isVisibilitySettingOn = settings[key + '.notification_visibility'] === 'true';
  logd(`Key '${key}' isVisibilitySettingInCdmOn: ` + isVisibilitySettingOn);
  const isAppPresentInUserApps = appsDetails ? appsDetails?.filter(it => it.key === key)?.length > 0 : false;
  logd(`Key '${key}' isAppPresentInUserApps: ` + isAppPresentInUserApps);
  return isVisibilitySettingOn && isAppPresentInUserApps;
}

export function* clearApplicationNotifications() {
  yield put(clearNotifications());
}

export function* clearApplicationNotificationFilter() {
  yield put(setNotificationFilter(undefined));
}

export function* loadApplicationsNotifications(ignoreCacheValidity: boolean) {
  const stateValidUntilAt: Date | undefined = yield select(getStateValidUntilAt);
  if (!ignoreCacheValidity && stateValidUntilAt && new Date() <= stateValidUntilAt) {
    logd('Skipping fetching of applications notifications, data are still valid');
    return;
  }

  const isOnline: boolean | undefined = yield select(getIsAppOnline);
  if (true !== isOnline && !isWeb) {
    logd('Skipping fetching of applications notifications, app is not online');
    return;
  }

  yield call(loadDisplaySettingsConfig);
  logd('Fetching of applications notifications has started');
  const userApps: UserApplications | undefined = yield select(getUserAppsSelector);
  const selectedGroup: string | undefined = yield select(getSelectedUserGroup);
  const userName: string = yield select(getUserLoginSelector);
  const logicsEmployeeId: string | undefined = yield select(getLogicsEmployeeIdSelector);

  /// Load notifications count from different services if enabled
  const settings: ParentSettings = yield select(getSettingsSelector);
  const effectsForFetchingNotifications: Generator<any, void, any>[] = [];
  const appsDetails: App[] | undefined = yield select(getAppsDetailsSelector);

  if (isDisplayable('inc_mng', settings, appsDetails)) {
    logd('set loading inc_mng');
    yield put(setLoading('inc_mng_phase_1'));
    effectsForFetchingNotifications.push(loadRequestCountIncMng(userName, selectedGroup, userApps));
  }

  if (isDisplayable('indApr', settings, appsDetails)) {
    logd('set loading indApr');
    yield put(setLoading('indApr_phase_1'));
    effectsForFetchingNotifications.push(loadRequestCountIndirectApproval(userName));
  }

  if (isDisplayable('306090', settings, appsDetails)) {
    logd('set loading 306090');
    yield put(setLoading('306090_phase_1'));
    effectsForFetchingNotifications.push(loadRequestCountHourlyReviews(logicsEmployeeId));
  }

  const notificationData: ApplicationNotificationData = yield select(getApplicationNotifications);
  for (const it of notificationData.notificationGroups) {
    yield put(setLoading(it.appKey));
  }

  effectsForFetchingNotifications.push(getUmNotifications(userName, selectedGroup));
  yield all(effectsForFetchingNotifications);
  yield put(resetValueNotFetchFromApiUntilAt());
}

// selector
export const getStateValidUntilAt = (state: any): Date | undefined => {
  return state.applicationNotification.data.stateValidUntilAt;
};

export const getApplicationNotifications = (state: any): ApplicationNotificationData => {
  return state.applicationNotification.data;
};

export const getInitTimeout = (state: any): number | undefined => {
  return state.applicationNotification.settings.initTimeout;
};

export const getNotificationTitleLengthLimit = (state: any): number => {
  return state.applicationNotification.settings.groupTitleLimit;
};

export const getAreNotificationFromPhase2VisibleToEveryone = (state: any): boolean => {
  return state.applicationNotification.settings?.visibleToEveryone === true;
};

export const getNotificationFiler = (state: any): NotificationFilter | undefined => {
  return state.applicationNotification?.data?.filter;
};

export const getCountOfApplicationNotifications = (state: any): number => {
  return getNotificationGroups(state)
    .filter(it => !it.loading && it.active)
    .map(it => it.countOfNotifications)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
};

export const areNotificationsLoading = (state: any): boolean => {
  return getNotificationGroups(state).filter(it => it.loading && it.active).length > 0;
};

export const areAllNotificationsGroupFromPhase1Inactive = (state: any): boolean => {
  const notificationGroupsFromPhase1 = getNotificationGroups(state).filter(
    it => it.appKey && ['inc_mng_phase_1', 'indApr_phase_1', '306090_phase_1'].includes(it.appKey),
  );
  return notificationGroupsFromPhase1.filter(it => !it.active).length === notificationGroupsFromPhase1.length;
};

export function getNotificationGroups(state: any): NotificationGroupData[] {
  return state.applicationNotification?.data?.notificationGroups || [];
}

export function getNotificationSeverities(state: any): NotificationSeverity[] {
  return state.applicationNotification?.settings?.notificationSeverities || [];
}

export function getFilterMaxRows(state: any): number {
  return state.applicationNotification?.settings?.filterMaxRows || DEFAULT_FILTER_MAX_ROWS;
}

export function getListTimeoutSelector(state: any): number {
  return state.applicationNotification?.settings?.listTimeout || DEFAULT_LIST_TIMEOUT_IN_SECONDS;
}

// Action Creators
export const reloadApplicationsNotifications = () => ({
  type: RELOAD_NOTIFICATIONS,
  payload: {},
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS,
  payload: {},
});

export const clearNotificationSettings = () => ({
  type: CLEAR_NOTIFICATION_SETTINGS,
  payload: {},
});

export const setLoading = (appKey: string) => ({
  type: SET_NOTIFICATION_LOADING,
  payload: {
    notification: {
      appKey: appKey,
      active: true,
      loading: true,
      error: false,
      countOfNotifications: 0,
    },
  },
});

export const setNotificationFilter = (filter: NotificationFilter | undefined) => ({
  type: SET_NOTIFICATION_FILTER,
  payload: {
    filter: filter,
  },
});

export const loadApplicationsNotificationsAction = () => ({
  type: RELOAD_NOTIFICATIONS,
  payload: {},
});

export const resetValueNotFetchFromApiUntilAt = () => ({
  type: RESET_STATE_VALID_UNTIL,
  payload: {},
});

export const setIsNotificationsListOpen = (appKey: string, value: boolean) => ({
  type: SET_IS_NOTIFICATIONS_LIST_OPEN,
  payload: {
    appKey: appKey,
    isNotificationsListOpen: value,
  },
});

export const resetIsNotificationsListOpen = () => ({
  type: RESET_IS_NOTIFICATIONS_LIST_OPEN,
  payload: {},
});

// Api calls for counts
function* loadRequestCountIncMng(
  login: string,
  selectedUserGroup: string | undefined,
  userApplications: UserApplications | undefined,
) {
  const appKey = 'inc_mng_phase_1';
  const roles = userApplications ? userApplications.inc_mng : [];
  const facilities: string[] | undefined = roles && extractIdsOfGivenTypeFromDl(roles, 'Facility');
  const facilityId = facilities && facilities.length > 0 ? facilities[0] : undefined;
  const url = `${ROOT_DHLLINK}/opsmgt/${
    `v1/incmng/number-incidents?user=${login}` +
    (isString(facilityId) ? `&facility_id=${facilityId}` : ``) +
    (isString(selectedUserGroup) ? `&user_group=${selectedUserGroup}` : ``)
  }`;

  try {
    // @ts-ignore
    const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
    yield mapResponseToState(response.number_of_overdue_incidents, response, appKey);
  } catch (e: any) {
    mapResponseCatchErr(e, appKey);
  }
}

function* loadRequestCountIndirectApproval(login: string) {
  const appKey = 'indApr_phase_1';

  // @ts-ignore
  const initData = yield loadValue(appIndApr.initDataKey);
  if (initData) {
    const initObject = JSON.parse(initData);
    // @ts-ignore
    const usergroup = yield loadValue(KEY_SELECTED_USERGROUP_ID);
    // @ts-ignore
    const userAppsString = yield loadValue('userApps');
    let strongestRole: string | null = '';
    if (userAppsString) {
      const userApps = JSON.parse(userAppsString);
      if (userApps && userApps.indApr) {
        strongestRole = pickStrongestRoleLabel(userApps.indApr);
      }
    }

    const configValues = {
      facility_id: initObject.facility_id,
      warehouse_id: initObject.warehouse_id,
      role: strongestRole,
      userGroup: usergroup,
    };

    const url = `${ROOT_DHLLINK}/opsmgt/${
      `v1/wlm/numberindirectrequests/facilities/${configValues.facility_id}/warehouse/${configValues.warehouse_id}/users/${login}/role/${configValues.role}` +
      (configValues.userGroup != null ? `?smartops_user_group=${configValues.userGroup}` : ``)
    }`;

    try {
      // @ts-ignore
      const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
      const countOfNotifications = response.number_of_opened_indirect_requests;
      yield mapResponseToState(countOfNotifications, response, appKey);
    } catch (e: any) {
      yield mapResponseCatchErr(appKey, e);
    }
  } else {
    yield mapResponseToState(0, null, appKey);
  }
}

function* loadRequestCountHourlyReviews(logicsEmployeeId: string | undefined) {
  const appKey = '306090_phase_1';
  if (logicsEmployeeId) {
    const url = `${ROOT_DHLLINK}/opsmgt/v1/reviews?smartops_user_logics_id=${logicsEmployeeId}`;
    try {
      // @ts-ignore
      const response = yield call(apiCall, {url, headers: JSON_HEADER, timeout: 20000});
      const countOfNotifications = response ? response.filter((r: any) => r.review_status === 0).length : 0;
      yield mapResponseToState(countOfNotifications, response, appKey);
    } catch (e) {
      yield mapResponseCatchErr(appKey, e);
    }
  } else {
    yield mapResponseToState(0, null, appKey);
  }
}

function* mapResponseCatchErr(appKey: string, e: any) {
  logw('Fetching notification failed for app: ' + appKey + ' with err: ' + JSON.stringify(e));
  yield put({
    type: SET_NOTIFICATION_GROUP,
    payload: {
      notification: {
        appKey: appKey,
        active: true,
        loading: false,
        error: true,
        countOfNotifications: 0,
      },
    },
  });

  if (e && e.error === I18n.t('errors.authExpired')) {
    if (isWeb) {
      yield put(logoutAttemptAction(I18n.t('errors.authExpired')));
    } else {
      DeviceEventEmitter.emit(TOKEN_REFRESH_FAILED_BROADCAST);
    }
  }
}

function pickStrongestRoleLabel(roles: any[]): string | null {
  if (roles && Array.isArray(roles)) {
    const roleNames = roles.map(role => role.label);
    for (const role of ['manager', 'supervisor', 'usergroupmember']) {
      if (roleNames.includes(role)) {
        return role;
      }
    }
  }
  return null;
}

function* mapResponseToState(countOfNotifications: number, response: any, appKey: string) {
  if (countOfNotifications !== undefined) {
    logd('Fetched notifications for: ' + appKey + ', countOfNotifications: ' + countOfNotifications);

    yield put({
      type: SET_NOTIFICATION_GROUP,
      payload: {
        notification: {
          appKey: appKey,
          active: true,
          loading: false,
          error: false,
          countOfNotifications: countOfNotifications,
        },
      },
    });
  } else {
    logw('Unexpected API response: ' + response + ' for appKey' + appKey + ' ', response);
    yield put({
      type: SET_NOTIFICATION_GROUP,
      payload: {
        notification: {
          appKey: appKey,
          active: true,
          loading: false,
          error: true,
          countOfNotifications: 0,
        },
      },
    });
  }
}

/// Call for notifications
function* getUmNotifications(login: string, groupId: string | undefined) {
  const areNotificationsFromV2Enabled: boolean = yield select(getAreNotificationFromPhase2VisibleToEveryone);
  if (!areNotificationsFromV2Enabled) {
    logd('Skipping fetching notifications from phase2, they are not enabled');
    return;
  }

  const url = `${ROOT_DHLLINK}/opsmgt/v1/notification/notification-list`;
  try {
    // @ts-ignore
    const user: object = yield select(getUserSelector) || {};
    // @ts-ignore
    const langCode: string = user?.language || 'en';
    const timeout: number = yield select(getListTimeoutSelector);
    const body = {smartops_user: login, smartops_user_group: groupId, language: langCode};
    logd('Um notification request body:  ' + JSON.stringify(body));

    // @ts-ignore
    const response = yield call(apiCall, {
      url,
      headers: JSON_HEADER,
      method: 'POST',
      timeout: timeout * 10000,
      addStatus: true,
      body: JSON.stringify(body),
    });
    logd('Um notification response body:  ' + JSON.stringify(response?.body.notifications));
    const severities: NotificationSeverity[] = yield select(getNotificationSeverities) || [];
    const notificationItems: NotificationData[] =
      response?.body?.notifications?.map((notificationFromApi: NotificationDataApiResponse) => {
        const severityByLabel = severities.find(it => notificationFromApi.priority === it.label);
        const defaultSeverity = severities.find(it => 'medium' === it.label);
        const severity = severityByLabel != undefined ? severityByLabel : defaultSeverity;
        const notification: NotificationData = {
          id: notificationFromApi.id,
          type: notificationFromApi.type,
          title: notificationFromApi.title,
          description: notificationFromApi.description,
          updated: notificationFromApi.updated,
          appTrigger: notificationFromApi.app_trigger,
          severity: severity,
        };
        return notification;
      }) || [];

    yield put({
      type: SET_NOTIFICATION_ITEMS,
      payload: {
        items: notificationItems,
      },
    });
  } catch (e) {
    logw('Fetching UM notifications failed with err: ' + JSON.stringify(e));
    // no need for yield mapResponseCatchErr(appKey, e);
  }
}

export function* loadDisplaySettingsConfig() {
  const areNotificationsFromV2Enabled: boolean = yield select(getAreNotificationFromPhase2VisibleToEveryone);
  if (!areNotificationsFromV2Enabled) {
    logd('Skipping fetching settings for notifications from phase2, they are not enabled');
    return;
  }
  const url = `${ROOT_DHLLINK}/opsmgt/v1/initwatch/notifications`;
  try {
    const timeout: number = yield select(getInitTimeout);
    // @ts-ignore
    const response: any = yield call(apiCall, {url, method: 'GET', timeout: timeout * 10000});
    //logd('From CDM was fetched following notificationConfig: ' + JSON.stringify(response));
    const severities: NotificationSeverity[] =
      response.notification_severities?.map((item: any) => {
        return {
          label: item.label,
          name: item.name,
          order: item.order,
          translationKey: item.translation_key,
          colorHexa: item.color_hexa,
        };
      }) || [];

    yield put({
      type: SET_SEVERITIES,
      payload: {
        settings: {
          notificationSeverities: severities,
        },
      },
    });

    for (const notificationType of response.notification_types || []) {
      const notificationData: NotificationGroupData = {
        appKey: notificationType.label,
        active: true,
        loading: false,
        error: false,
        countOfNotifications: 0,
        notifications: [],
        isNotificationsListOpen: false,
        settings: {
          name: notificationType.name,
          order: notificationType.order,
          translationKey: notificationType.translation_key,
          iconBase64: 'data:image/png;base64,' + notificationType.icon_base64,
        },
      };
      yield put({
        type: SET_NOTIFICATION_GROUP,
        payload: {
          notification: notificationData,
        },
      });
    }
  } catch (e: any) {
    logw(`Fetching notification display settings from CDM failed with error: ${JSON.stringify(e)}`);
  }
}
