import {StyleSheet, Text, View} from 'react-native';
import {dhlBlack, dhlBlue} from '@smartops/smartops-shared';
import {App} from '../types';
import React from 'react';
import {Launch, NotificationData} from '../utils/types';
import {useSelector} from 'react-redux';
import {getAppsDetailsSelector} from '../flows/apps';
import AppOpener from '../features/AppOpener';
import {formatDateTime} from '../utils/DateTime';
import {getAndroidLocale, getIs24HourFormat} from '../flows/app';
import i18n from '../features/I18n';

interface NotificationDetailProps {
  notification: NotificationData;
}

const NotificationDetailScreen = (props: NotificationDetailProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const appsDetails: App[] = useSelector(getAppsDetailsSelector);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const androidLocale: string | undefined = useSelector(getAndroidLocale);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const is24HourFormat: boolean | undefined = useSelector(getIs24HourFormat);
  const buildKioskAppData = (launch: Launch) => {
    const launchFields = launch?.fields && launch.fields.find(it => it.url);
    const app: App = {
      key: launch.app_label || 'undefined',
      // @ts-ignore
      webApp: true,
      linkEnable: false,
      name: launch.name || 'undefined',
      order: 1,
      url: launchFields?.url || 'undefined',
      image: '',
    };
    return app;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{props.notification.title}</Text>
      <Text style={{marginTop: 10}}>
        {props.notification.updated
          ? formatDateTime(props.notification.updated, is24HourFormat, androidLocale, 'DD-MMMM-YY')
          : ''}
      </Text>
      <Text style={styles.description}>{props.notification.description}</Text>
      <View style={{flex: 1, justifyContent: 'flex-end'}}>
        <View style={styles.wrapperAroundLinks}>
          {props.notification?.appTrigger?.launches
            ?.map(launch => {
              const foundApp =
                'kiosk_browser' === launch.app_label
                  ? buildKioskAppData(launch)
                  : appsDetails.find(app => app.key === launch.app_label);
              return {
                app: foundApp,
                launch: launch,
              };
            })
            .map(it => {
              const launchText = it.launch.name ? it.launch.name : i18n.t('notifications.launch');
              return (
                <AppOpener key={it.launch.name} style={styles.linkBox} app={it.app} launch={it.launch}>
                  <Text>
                    {' '}
                    <Text style={[styles.link]}>{launchText}</Text>{' '}
                  </Text>
                </AppOpener>
              );
            })}
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 10,
    alignItems: 'center',
  },
  title: {
    marginTop: 15,
    fontSize: 20,
    color: dhlBlack,
    fontWeight: 'bold',
  },
  description: {marginTop: 10, marginBottom: 10},
  linkBox: {padding: 7},
  link: {
    color: dhlBlue,
    textDecorationLine: 'underline',
  },
  wrapperAroundLinks: {
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingBottom: 15,
    flexDirection: 'row',
  },
});

export default NotificationDetailScreen;
