import {all, call, put, select, take, takeLatest} from 'redux-saga/effects';
import {
  GQL_UM_CORE_URL,
  GQL_UM_PIN_URL,
  GQL_UM_USER_URL,
  POST_WMS_PASSWORD,
  TOKEN_REFRESH_FAILED_BROADCAST,
} from '../config';
import I18n from 'features/I18n';
import {storeAppsDetailsAction, storeFavoriteAppsKeysAction} from './apps';
import {apiCall} from '../utils/Oauth';
import {
  KEY_ACCESS_TOKEN,
  KEY_APP_SETTINGS,
  KEY_APPS_DETAILS,
  KEY_AUTH_TYPE,
  KEY_BR_SWITCH_SCREEN,
  KEY_BUSINESS_RELEASE,
  KEY_FAVORITE_APPS_KEYS,
  KEY_FIRST_NAME,
  KEY_GROUP_ATTRIBUTES_VALUE_NAMES,
  KEY_GROUP_IDS_COUNTRIES_MAP,
  KEY_GROUP_IDS_FACILITIES_MAP,
  KEY_GROUPS_APPS_COUNTRIES_MAP,
  KEY_GROUPS_APPS_FACILITIES_MAP,
  KEY_IS_ADMIN,
  KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU,
  KEY_IS_LOGIN_PROCESS_COMPLETED,
  KEY_IS_TABLET,
  KEY_LANGUAGE,
  KEY_LAST_NAME,
  KEY_LOCKSCREEN_DATA,
  KEY_LOGICS_EMPLOYEE_ID,
  KEY_LOGIN,
  KEY_REFRESH_TOKEN,
  KEY_SELECTED_USERGROUP_ID,
  KEY_SHOW_NUMPAD,
  KEY_SMARTREM_ID,
  KEY_USER_APPS,
  KEY_USER_APPS_UM_RESPONSE,
  KEY_USER_EMAIL,
  KEY_USER_GROUP_ID,
  KEY_USER_GROUP_IDS,
  KEY_USER_GROUPS,
  KEY_USER_PASSWORD,
  KEY_USER_ROLES,
  KEY_WEB_PATHS,
  KEY_WMS_SSO_INIT_DATA,
  loadJson,
  loadValue,
  saveValue,
} from '../features/sharedPrefs';
import {logd, logi, logw} from '../features/logging';
import EncryptedStorage from 'react-native-encrypted-storage';
import {
  setBusinessReleaseAction,
  setShowSelectGroupModalAction,
  storeIsTabletAction,
  storeWmsSsoDataAction,
  storeWmsSsoMetaDataAction,
} from './app';
import {getAccessTokenSelector, getUserAppsSelector, getUserSelector} from '../features/selectors';
import {applications, changePasswordBody, changePinBody, userDetails} from '../graphql/requestBodies';
import {logObject} from '../utils/Utils';
import {logoutAttemptAction, passwordChangeResultAction, saveDataForLockscreen} from './login';
import {updatePin} from '@smartops/react-native-multiprocess-preferences';
import {Validator} from 'jsonschema';
import {REST_RESPONSE_BAD_RESPONSE} from '../actions/types';
import {showToast} from 'features/platformSpecific';
import {
  navigationPushAction,
  navigationPopToAction,
  navigationReloadWithPropsAction,
  getCurrentScreenSelector as getCurrentScreen,
  navigationPopAction,
} from '@smartops/smartops-shared';
import {isWeb} from 'features/platformSpecific';
//import md5 from 'md5';
import sha256 from 'sha256';
import {DeviceEventEmitter} from 'react-native';
import {setSettings} from './settings';
import {clearNotifications, clearNotificationSettings, setNotificationFilter} from './applicationNotification';

// actions
const STORE__USER = 'STORE__USER';
const STORE__TOKENS = 'STORE__TOKENS';
const STORE__USER_APPS = 'STORE__USER_APPS';
const CHANGE_PIN = 'CHANGE_PIN';
const STORE__AUTH_TYPE = 'STORE__AUTH_TYPE';
const PRIVACY_NOTIFICATION_CHOICE = 'PRIVACY_NOTIFICATION_CHOICE';
const USER_GROUP_SELECTED = 'USER_GROUP_SELECTED';
const STORE__IS_ADMIN = 'STORE__IS_ADMIN';
const STORE__USER_GROUPS = 'STORE__USER_GROUPS';
const STORE__SELECTED_USER_GROUP = 'STORE__SELECTED_USER_GROUP';
const SET__USER_GROUPS = 'SET__USER_GROUPS';
const SET__SHOW_PASS_NOTIFICATION = 'SET__SHOW_PASS_NOTIFICATION';
const SET__GROUP_IS_UPDATING = 'SET__GROUP_IS_UPDATING';
const SET__ATTRIBUTE_VALUE_NAMES = 'SET__ATTRIBUTE_VALUE_NAMES';
const SET__LOGICS_EMPLOYEE_ID = 'SET__LOGICS_EMPLOYEE_ID';
const SET__SHOW_NUMPAD_VAL = 'SET__SHOW_NUMPAD_VAL';
const STORE__SHOW_NUMPAD_VAL = 'STORE__SHOW_NUMPAD_VAL';
const SET__SMART_SCAN_COUNTRY = 'SET__SMART_SCAN_COUNTRY';
const SET__VELOCITY_SCAN_COUNTRY = 'SET__VELOCITY_SCAN_COUNTRY';
const SET__IS_ALLOWED_TO_SHOW_BOTTOM_MENU = 'SET__IS_ALLOWED_TO_SHOW_BOTTOM_MENU';
export const USER_GROUP_CONTINUE_LOGIN = 'USER_GROUP_CONTINUE_LOGIN';

const INITIAL_STATE = {
  user: {
    firstName: undefined,
    lastName: undefined,
    login: undefined,
    smartremId: undefined,
    language: undefined,
    countryCode: undefined,
    email: undefined,
    authType: 'authenticationType.pinAuth',
    isAdmin: false,
  },
  isAllowedToShowBottomBarMenu: false,
  accessToken: undefined,
  refreshToken: undefined,
  userApplications: undefined,
  userGroups: undefined,
  selectedUserGroup: undefined, //Active group
  groupUpdateInProgress: false,
  attributeValueNames: undefined,
  logicsEmployeeId: undefined,
  showNumpad: true,
  smartScanCountry: undefined,
  velocityScanCountry: undefined,
};

export default function authReducer(state = INITIAL_STATE, action = {}) {
  // logd('Debug redux logger: Action ' + action.type);
  switch (action.type) {
    case STORE__USER:
      return {...state, user: {authType: state.user.authType, ...action.payload}};
    case STORE__TOKENS:
      return {...state, accessToken: action.payload.accessToken, refreshToken: action.payload.refreshToken};
    case STORE__USER_APPS:
      return {...state, userApplications: action.payload};
    case STORE__AUTH_TYPE:
      return {...state, user: {...state.user, authType: action.payload}};
    case STORE__IS_ADMIN:
      return {...state, user: {...state.user, isAdmin: action.payload}};
    case SET__USER_GROUPS:
      return {...state, userGroups: action.payload};
    case STORE__SELECTED_USER_GROUP:
      return {...state, selectedUserGroup: action.payload};
    case SET__GROUP_IS_UPDATING:
      return {...state, groupUpdateInProgress: action.payload};
    case SET__ATTRIBUTE_VALUE_NAMES:
      return {...state, attributeValueNames: action.payload};
    case SET__LOGICS_EMPLOYEE_ID:
      return {...state, logicsEmployeeId: action.payload};
    case SET__SHOW_NUMPAD_VAL:
      return {...state, showNumpad: action.payload};
    case SET__SMART_SCAN_COUNTRY:
      return {...state, smartScanCountry: action.payload};
    case SET__VELOCITY_SCAN_COUNTRY:
      return {...state, velocityScanCountry: action.payload};
    case SET__IS_ALLOWED_TO_SHOW_BOTTOM_MENU:
      return {...state, isAllowedToShowBottomBarMenu: action.payload};
    default:
      return state;
  }
}

// selectors
export const getUserLoginSelector = state => state.auth.user.login;
export const getUserSmartremId = state => state.auth.user.smartremId;
export const isAllowedToShowBottomBarMenuSelector = state => state.auth.isAllowedToShowBottomBarMenu;
export const getLogicsEmployeeIdSelector = state => state.auth.logicsEmployeeId;
export const getUserNameSelector = state => state.auth.user.firstName;
export const getUserLastNameSelector = state => state.auth.user.lastName;
export const getUserCountryCode = state => state.auth.user.countryCode;
export const getRefreshTokenSelector = state => state.auth.refreshToken;
export const getAuthType = state => state.auth.user.authType;
export const getSelectedUserGroup = state => state.auth.selectedUserGroup;
export const getUserGroups = state => state.auth.userGroups;
export const getSmartScanCountry = state => state.auth.smartScanCountry;
export const getVelocityScanCountry = state => state.auth.velocityScanCountry;

// action creators
export const storeUserAction = payload => ({type: STORE__USER, payload});
export const storeTokensAction = (accessToken, refreshToken) => ({
  type: STORE__TOKENS,
  payload: {accessToken, refreshToken},
});
export const storeUserAppsAction = payload => ({type: STORE__USER_APPS, payload});
export const changePinAction = payload => ({type: CHANGE_PIN, payload});
export const storeAuthTypeAction = payload => ({type: STORE__AUTH_TYPE, payload});
export const setPrivacyNotificationResult = payload => ({type: PRIVACY_NOTIFICATION_CHOICE, payload});
export const storeIsAdminAction = payload => ({type: STORE__IS_ADMIN, payload});
export const storeUserGroupsAction = payload => ({type: STORE__USER_GROUPS, payload});
export const setShowPassNotification = payload => ({type: SET__SHOW_PASS_NOTIFICATION, payload});
export const storeSelectedUserGroupAction = payload => ({type: STORE__SELECTED_USER_GROUP, payload});
export const setUserGroupsAction = payload => ({type: SET__USER_GROUPS, payload});
export const userGroupSelectedAction = payload => ({type: USER_GROUP_SELECTED, payload});
export const userGroupContinueLoginAction = payload => ({type: USER_GROUP_CONTINUE_LOGIN, payload});
export const setGroupIsUpdatingAction = payload => ({type: SET__GROUP_IS_UPDATING, payload});
export const setAttributeValueNamesAction = payload => ({type: SET__ATTRIBUTE_VALUE_NAMES, payload});
export const setLogicsEmployeeIdAction = payload => ({type: SET__LOGICS_EMPLOYEE_ID, payload});
export const setSavedShowNumpad = payload => ({type: SET__SHOW_NUMPAD_VAL, payload});
export const persistShowNumpad = payload => ({type: STORE__SHOW_NUMPAD_VAL, payload});
export const setSmartScanCountry = payload => ({type: SET__SMART_SCAN_COUNTRY, payload});
export const setVelocityScanCountry = payload => ({type: SET__VELOCITY_SCAN_COUNTRY, payload});
export const setIsAllowedToShowBottomBarMenuAction = payload => ({type: SET__IS_ALLOWED_TO_SHOW_BOTTOM_MENU, payload});

export function* allAuthFlows() {
  yield all([
    takeLatest(CHANGE_PIN, changePinWatcher),
    takeLatest(STORE__USER_GROUPS, saveUpdatedUserGroups),
    takeLatest(STORE__SHOW_NUMPAD_VAL, saveNumpadVal),
  ]);
}

function* saveNumpadVal(action) {
  yield put(setSavedShowNumpad(action.payload));
  yield call(saveValue, KEY_SHOW_NUMPAD, JSON.stringify(action.payload));
}

function* changePinWatcher(action) {
  //logd('Changing password', action.payload);
  logi('Changin Password');
  const params = {
    oldPass: action.payload.oldPassword,
    newPass: action.payload.newPassword,
    previousScreen: action.payload.previousScreen,
  };
  const attemptNumber = action.payload.attemptNumber || 1;
  const authType = yield select(getAuthType);
  const isPinAuth = authType === 'authenticationType.pinAuth';
  let errorMessage = false;
  try {
    const changePin = yield call(changePinOrPassGql, params.oldPass, params.newPass, isPinAuth);
    logi(`Change PIN/password response`, changePin);
    if (!changePin || (changePin && changePin.errors && changePin.errors.length)) {
      errorMessage = changePin
        ? I18n.t(isPinAuth ? 'pinInitial.wrongNewPin' : 'pwdInitial.wrongNewPass')
        : I18n.t('api.serverError');
    } else {
      yield call(storeUserPasswordToEncryptedStorage, params.newPass);
    }
  } catch (e) {
    logi(`setNewPIN error: ${e}`);
    errorMessage = I18n.t('api.serverError');
  }
  if (errorMessage) {
    yield put(
      navigationReloadWithPropsAction({
        error: errorMessage,
        oldPassword: action.payload.isInitial ? action.payload.oldPassword : undefined,
        attemptNumber,
      }),
    );
  } else {
    showToast(I18n.t(isPinAuth ? 'pinInitial.pinChanged' : 'pwdInitial.pinChanged'));
    if (params.previousScreen) {
      yield put(navigationPopToAction(params.previousScreen));
      yield put(passwordChangeResultAction());
    } else {
      yield put(navigationPushAction('pageMain'));
    }
    const username = yield select(getUserNameSelector);
    const lastname = yield select(getUserLastNameSelector);
    yield call(
      saveDataForLockscreen,
      {
        firstName: username,
        lastName: lastname,
      },
      params.newPass,
    );
  }
}

export async function storeUserPasswordToEncryptedStorage(password) {
  try {
    if (isWeb) {
      await saveValue(KEY_USER_PASSWORD, sha256(password));
    } else {
      await EncryptedStorage.setItem(
        KEY_USER_PASSWORD,
        typeof password === 'string' ? password : JSON.stringify(password || 0),
      );
    }
  } catch (error) {
    // There was an error on the native side
    logw(`Error in storeUserPasswordToEncryptedStorage: `, error);
  }
  if (isWeb) return;
  //Legacy password save TODO remove this when the new lockscreen is configured
  //The old lockscreen is adding always quotes, so we have to stringify
  updatePin(typeof password === 'string' ? JSON.stringify(password) : JSON.stringify(password + ''))
    .then(() => {
      logi('pin-secret successful updated');
    })
    .catch(error => {
      logw('could not update pin-secret', error);
    });
}

export async function checkPassword(passwordToCheck) {
  try {
    const password = isWeb ? await loadValue(KEY_USER_PASSWORD) : await EncryptedStorage.getItem(KEY_USER_PASSWORD);
    if (typeof passwordToCheck === 'string') {
      return isWeb ? sha256(passwordToCheck) === password : passwordToCheck === password;
    } else if (typeof passwordToCheck === 'number') {
      return isWeb ? sha256(passwordToCheck) === password : JSON.stringify(passwordToCheck) === password;
    } else {
      return false;
    }
  } catch (error) {
    // There was an error on the native side
    logw(`Error in storeUserPasswordToEncryptedStorage: `, error);
  }
}

function* saveUpdatedUserGroups(action) {
  const userGroups = yield select(getUserGroups);
  const groupId = action.payload;
  let updatedUserGroups;
  const body = {
    attribute_level: 'group_attribute',
    group_id: groupId,
    attribute_label: 'default_group',
    value: true,
  };
  body.username = yield select(getUserLoginSelector);
  const bodyToSend = [body];
  logi(`saveUpdatedUserGroups bodyToSend: `, bodyToSend);
  const url = POST_WMS_PASSWORD;
  let response = {};
  let isTimeoutError = false;
  yield put(setGroupIsUpdatingAction(true));
  try {
    const headers = {'Content-Type': 'application/json'};
    const options = {
      url,
      headers,
      method: 'POST',
      body: JSON.stringify(bodyToSend),
      addStatus: true,
      canUseRefreshToken: true,
    };
    response = yield call(apiCall, options);
    logi(`Set default user group response: `, logObject(response));
  } catch (e) {
    logw('Error setting default user group', e);
    if (e?.message?.includes('timeout')) {
      isTimeoutError = true;
    }
    if ((yield select(getCurrentScreen)) !== 'pageLogin' && e && e.error === I18n.t('errors.authExpired')) {
      if (isWeb) {
        yield put(logoutAttemptAction(I18n.t('errors.authExpired')));
      } else {
        DeviceEventEmitter.emit(TOKEN_REFRESH_FAILED_BROADCAST);
      }
      return;
    }
  }
  logi(`saveUpdatedUserGroups response: `, response);
  let isSuccess = false;
  if (response && response.status === 200) {
    isSuccess = true;
    showToast(I18n.t('userGroup.defaultGroupUpdateSuccess'));
  } else {
    let errorMessage;
    if (response && response.status == null) {
      if (isTimeoutError) {
        errorMessage = I18n.t('api.noResponseError');
      } else {
        errorMessage = I18n.t('errors.connectionNotAvailable');
      }
    } else if ((response && response.status >= 400 && response.status < 500) || response.status >= 500) {
      errorMessage = I18n.t('api.serverError');
    }
    showToast(errorMessage);
    logi(`saveUpdatedUserGroups errorMessage: ${errorMessage}`);
  }
  yield put(setGroupIsUpdatingAction(false));
  if (groupId != null && userGroups && isSuccess) {
    updatedUserGroups = userGroups.map(g => ({...g, isDefault: groupId === g.id}));
    yield call(saveValue, KEY_USER_GROUPS, JSON.stringify(updatedUserGroups));
    yield put(setUserGroupsAction(updatedUserGroups));
  }
}

export function* showPrivacyNotificationScreen() {
  if ((yield select(getCurrentScreen)) !== 'privacyNotificationScreen') {
    yield put(navigationPushAction('privacyNotificationScreen'));
  }
  const result = yield take(PRIVACY_NOTIFICATION_CHOICE);
  const privacyAcceptedResult = result.payload;
  logi(`showPrivacyNotificationScreen privacyAcceptedResult: `, privacyAcceptedResult);
  if ((yield select(getCurrentScreen)) !== 'pageLogin') {
    privacyAcceptedResult
      ? yield put(navigationPopAction({clearFields: false, isLoading: true}))
      : yield put(navigationPopAction({clearFields: true, authPresent: false}));
  }
  return privacyAcceptedResult;
}

export function* showSelectUserGroupScreenWatcher() {
  while (true) {
    const res = yield take(USER_GROUP_SELECTED);
    if ((yield select(getCurrentScreen)) === 'pageLogin' && res?.payload !== null) {
      yield put(setShowSelectGroupModalAction(true));
    }
  }
}

export function* getUserDetailsFromGqlUm(login) {
  try {
    const headers = {
      'Content-Type': 'application/graphql',
    };
    const options = {
      url: GQL_UM_USER_URL,
      body: userDetails(login),
      headers,
      method: 'POST',
      timeout: 30000,
      canUseRefreshToken: true,
    };
    const resp = yield call(apiCall, options);
    if (!validateDetailsResponse(resp)) {
      return {error: REST_RESPONSE_BAD_RESPONSE, message: I18n.t('errors.invalidResponse')};
    }
    return resp;
  } catch (e) {
    logi('getUserDetailsFromGqlUm failed', e);
    return e;
  }
}

function validateDetailsResponse(response) {
  const v = new Validator();
  const userDetailSchema = {
    id: '/UserDetail',
    type: 'object',
    properties: {
      firstName: {type: 'string'},
      lastName: {type: 'string'},
    },
    required: ['firstName', 'lastName'],
  };
  const userByLoginSchema = {
    id: '/UserByLogin',
    type: 'object',
    properties: {
      id: {type: 'string'},
      login: {type: 'string'},
      authType: {type: 'string'},
      passwordType: {type: 'string'},
      userDetail: {$ref: '/UserDetail'},
      showPasswordWillExpireNotification: {type: 'boolean'},
      daysToPasswordExpiration: {type: 'integer'},
      authorizations: {
        type: 'array',
        items: {type: 'object'},
      },
      assignedGroups: {
        type: 'array',
        items: {type: 'object'},
      },
    },
    required: [
      'assignedGroups',
      'authorizations',
      'id',
      'login',
      'authType',
      'passwordType',
      'userDetail',
      'showPasswordWillExpireNotification',
      'daysToPasswordExpiration',
    ],
  };
  const dataSchema = {
    id: '/Data',
    type: 'object',
    properties: {
      userByLogin: {$ref: '/UserByLogin'},
    },
    required: ['userByLogin'],
  };
  const schema = {
    type: 'object',
    properties: {
      data: {$ref: '/Data'},
    },
    required: ['data'],
  };
  v.addSchema(userDetailSchema, '/UserDetail');
  v.addSchema(userByLoginSchema, '/UserByLogin');
  v.addSchema(dataSchema, '/Data');
  let res = v.validate(response, schema);
  if (res.errors && res.errors.length > 0) logi(`errors: `, res.errors);
  return res.valid;
}

export function* getAppsFromGqlUm() {
  try {
    const headers = {
      'Content-Type': 'application/graphql',
    };
    const options = {
      url: GQL_UM_CORE_URL,
      body: applications,
      headers,
      method: 'POST',
      timeout: 30000,
    };
    return yield call(apiCall, options);
  } catch (e) {
    logi('getAppsFromGqlUm failed: ', e);
    return null;
  }
}

export function* changePinOrPassGql(oldPwd, newPwd, isPin = true) {
  try {
    const login = yield select(getUserLoginSelector);
    const headers = {
      'Content-Type': 'application/graphql',
    };
    const options = {
      url: GQL_UM_PIN_URL,
      body: isPin ? changePinBody(oldPwd, newPwd, login) : changePasswordBody(oldPwd, newPwd, login),
      headers,
      method: 'POST',
      canUseRefreshToken: true,
      timeout: 30000,
    };
    return yield call(apiCall, options);
  } catch (e) {
    logi('changePinGql failed: ', e);
    return null;
  }
}

function* checkAuthenticationInRedux() {
  const login = yield select(getUserLoginSelector);
  const accessToken = yield select(getAccessTokenSelector);
  if (login && accessToken) {
    return true;
  }
}

function* loadUserAndTokensFromSharedPrefs() {
  let showNumpadVal = yield call(loadValue, KEY_SHOW_NUMPAD);
  if (showNumpadVal) yield put(setSavedShowNumpad(JSON.parse(showNumpadVal)));
  const login = yield call(loadValue, KEY_LOGIN);
  const firstName = yield call(loadValue, KEY_FIRST_NAME);
  const lastName = yield call(loadValue, KEY_LAST_NAME);
  const language = yield call(loadValue, KEY_LANGUAGE);
  const userGroupIds = yield call(loadJson, KEY_USER_GROUP_IDS);
  const accessToken = yield call(loadValue, KEY_ACCESS_TOKEN);
  const refreshToken = yield call(loadValue, KEY_REFRESH_TOKEN);
  let authType = yield call(loadValue, KEY_AUTH_TYPE);
  let isAdmin = yield call(loadJson, KEY_IS_ADMIN);
  const userGroups = yield call(loadJson, KEY_USER_GROUPS);
  let selectedUserGroup = yield call(loadValue, KEY_SELECTED_USERGROUP_ID);
  let logicsEmployeeId = yield call(loadValue, KEY_LOGICS_EMPLOYEE_ID);
  const attrValueNames = yield call(loadValue, KEY_GROUP_ATTRIBUTES_VALUE_NAMES);
  const isAllowedToShowBottomBarMenu = yield call(loadValue, KEY_IS_ALLOWED_TO_SHOW_BOTTOM_BAR_MENU);
  const smartremId = yield call(loadValue, KEY_SMARTREM_ID);
  if (!authType) authType = 'authenticationType.pinAuth';
  if (login && accessToken) {
    yield put(storeUserAction({login, firstName, lastName, language, userGroupIds, authType, isAdmin, smartremId}));
    yield put(storeTokensAction(accessToken, refreshToken));
    yield put(setIsAllowedToShowBottomBarMenuAction(isAllowedToShowBottomBarMenu));
    if (selectedUserGroup) yield put(storeSelectedUserGroupAction(selectedUserGroup));
    if (logicsEmployeeId) yield put(setLogicsEmployeeIdAction(logicsEmployeeId));
    if (attrValueNames) yield put(setAttributeValueNamesAction(JSON.parse(attrValueNames)));
    yield put(setUserGroupsAction(userGroups));
    if (isWeb) {
      const br = yield call(loadValue, KEY_BUSINESS_RELEASE);
      yield put(setBusinessReleaseAction(br));
    }
  }
}

export function* saveUserToSharedPrefs() {
  const user = yield select(getUserSelector);
  const selectedUserGroupId = yield select(getSelectedUserGroup);
  const userGroups = yield select(getUserGroups);
  if (user) {
    yield call(saveValue, KEY_LOGIN, user.login);
    yield call(saveValue, KEY_FIRST_NAME, user.firstName);
    yield call(saveValue, KEY_LAST_NAME, user.lastName);
    yield call(saveValue, KEY_LANGUAGE, user.language);
    yield call(saveValue, KEY_USER_EMAIL, user.email);
    yield call(saveValue, KEY_USER_GROUP_IDS, JSON.stringify(user.userGroupIds));
    yield call(saveValue, KEY_AUTH_TYPE, user.authType);
    yield call(saveValue, KEY_IS_ADMIN, JSON.stringify(user.isAdmin));
    yield call(saveValue, KEY_USER_GROUPS, JSON.stringify(userGroups));
    if (selectedUserGroupId) {
      logi(`setting selectedUserGroupId ${selectedUserGroupId}`);
      yield call(saveValue, KEY_SELECTED_USERGROUP_ID, selectedUserGroupId);
    } else {
      //Clear if no value exists
      yield call(saveValue, KEY_SELECTED_USERGROUP_ID, null);
    }
  }
}

export function* saveTokensToSharedPrefs() {
  const accessToken = yield select(getAccessTokenSelector);
  const refreshToken = yield select(getRefreshTokenSelector);
  if (accessToken && refreshToken) {
    yield call(saveValue, KEY_ACCESS_TOKEN, accessToken);
    yield call(saveValue, KEY_REFRESH_TOKEN, refreshToken);
  }
}

export function* saveIsLoginProcessCompleted(isLoginProcessCompleted) {
  yield call(saveValue, KEY_IS_LOGIN_PROCESS_COMPLETED, JSON.stringify(isLoginProcessCompleted));
}

export function* getIsLoginProcessCompleted() {
  let isLoginProcessCompleted = yield call(loadValue, KEY_IS_LOGIN_PROCESS_COMPLETED);
  return isLoginProcessCompleted != null ? JSON.parse(isLoginProcessCompleted) : true;
}

export function* saveUserAppsToSharedPrefs(roles, rolesInFormatThatMakesSense) {
  // for child apps
  yield call(saveValue, KEY_USER_ROLES, JSON.stringify(roles));
  yield call(saveValue, KEY_USER_APPS, JSON.stringify(rolesInFormatThatMakesSense));
  // for parent on skip login
  yield call(saveValue, KEY_USER_APPS_UM_RESPONSE, JSON.stringify(rolesInFormatThatMakesSense));
}

export function* filterAllAppsByUserApps(allApps) {
  const userApps = yield select(getUserAppsSelector);
  if (userApps) {
    const userAppsKeys = Object.keys(userApps);
    return allApps.filter(app => userAppsKeys.includes(app.key));
  }
  logw('No user applications in Redux store - should not happen');
  return allApps;
}

export function* loadUserAppsFromSharedPrefsAndStoreToRedux() {
  const userApps = JSON.parse(yield call(loadValue, KEY_USER_APPS_UM_RESPONSE));
  yield put(storeUserAppsAction(userApps));
}

export function* checkOrLoadAuthenticatedUser() {
  const inMemory = yield call(checkAuthenticationInRedux);
  if (inMemory) {
    return true;
  }
  yield call(loadUserAndTokensFromSharedPrefs);
  return yield call(checkAuthenticationInRedux);
}

export function* clearUserDataFromRedux() {
  yield put(storeUserAction({}));
  yield put(storeTokensAction(null, null));
  yield put(storeUserAppsAction(null));
  yield put(storeAppsDetailsAction(null));
  yield put(storeFavoriteAppsKeysAction(null));
  yield put(storeIsTabletAction(null));
  yield put(storeWmsSsoDataAction(null));
  yield put(storeWmsSsoMetaDataAction(null));
  yield put(setUserGroupsAction(undefined));
  yield put(userGroupSelectedAction(null));
  yield put(storeSelectedUserGroupAction(null));
  yield put(setBusinessReleaseAction(null));
  yield put(clearNotifications());
  yield put(clearNotificationSettings());
  yield put(setNotificationFilter(undefined));
}

export function* clearUserDataFromSharedPrefs() {
  yield call(saveIsLoginProcessCompleted, false);
  yield call(saveValue, KEY_ACCESS_TOKEN, null);
  yield call(saveValue, KEY_REFRESH_TOKEN, null);
  yield call(saveValue, KEY_LOGIN, null);
  yield call(saveValue, KEY_SMARTREM_ID, null);
  yield call(saveValue, KEY_FIRST_NAME, null);
  yield call(saveValue, KEY_LAST_NAME, null);
  //yield call(saveValue, KEY_LANGUAGE, null); SO-1009 keep the language for kiosk
  yield call(saveValue, KEY_USER_ROLES, null);
  yield call(saveValue, KEY_USER_APPS, null);
  yield call(saveValue, KEY_APPS_DETAILS, null);
  yield call(saveValue, KEY_FAVORITE_APPS_KEYS, null);
  yield call(saveValue, KEY_IS_TABLET, null);
  yield call(saveValue, KEY_WMS_SSO_INIT_DATA, null);
  yield call(saveValue, KEY_SELECTED_USERGROUP_ID, null);
  yield call(saveValue, KEY_USER_GROUPS, null);
  yield call(saveValue, KEY_LOGICS_EMPLOYEE_ID, null);
  yield call(saveValue, KEY_USER_PASSWORD, null);
  yield call(saveValue, KEY_WEB_PATHS, null);
  yield call(saveValue, KEY_LOCKSCREEN_DATA, '');
  //yield call(saveValue, KEY_BUSINESS_RELEASE, null);
  yield call(saveValue, KEY_BR_SWITCH_SCREEN, null);
  yield call(saveValue, KEY_GROUP_IDS_FACILITIES_MAP, null);
  yield call(saveValue, KEY_GROUP_IDS_COUNTRIES_MAP, null);
  yield call(saveValue, KEY_GROUPS_APPS_COUNTRIES_MAP, null);
  yield call(saveValue, KEY_GROUPS_APPS_FACILITIES_MAP, null);
  yield call(saveValue, KEY_USER_GROUP_ID, null);
  yield call(clearAppSettings); //TODO does this need to be cleared?
}

export function* clearAppSettings() {
  yield call(loadValue, KEY_APP_SETTINGS);
  const appSettings = yield call(loadJson, KEY_APP_SETTINGS);
  if (appSettings && appSettings.portal) {
    const valToSave = {portal: appSettings.portal};
    yield call(saveValue, KEY_APP_SETTINGS, JSON.stringify(valToSave));
    //logd(`clearAppSettings valToSave: `, valToSave);
  } else {
    logi(`clearAppSettings removing all values`);
    yield call(saveValue, KEY_APP_SETTINGS, null);
    yield put(setSettings(undefined));
  }
}
