import {NativeModules, Platform} from 'react-native';
import {toast} from 'react-toastify';
import {dhlBlack} from '@smartops/smartops-shared';
import {OAUTH_SECRET_PASSWORD, OAUTH_SECRET_USERNAME, ROOT_DHLLINK} from '../config';
import {getAppSettingsVal} from '../flows/apps';
import {logi} from 'features/logging';
import Base64 from '../utils/Base64';
import {doFetch} from '../utils/fetch';
import DeviceInfo from 'react-native-device-info';
import {ToastClassName, ToastOptions} from 'react-toastify/dist/types';

export const isWeb = Platform.OS === 'web';
let ToastAndroid: any;
if (!isWeb) {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  ({ToastAndroid} = require('react-native'));
}

const toastClassNameFunction: ToastClassName = () => {
  return 'default-toast-class';
};

const webToastOptions: ToastOptions = {
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  className: toastClassNameFunction({
    type: 'info',
    defaultClassName: 'default-name',
    position: 'bottom-center',
  }),
  closeOnClick: true,
  autoClose: 6000,
  style: {
    fontSize: 20,
    color: dhlBlack,
  },
};

export const showToast = (message: string, duration: number = 6000): void => {
  const isShort = duration === 2000;
  if (isWeb) {
    toast(message, {...webToastOptions, autoClose: isShort ? 2000 : 6000});
  } else {
    ToastAndroid.show(message, duration);
  }
};

export const getFlex = (num: number): any => {
  if (isWeb) {
    return {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: `${num}%`,
    };
  } else {
    return {
      flex: num,
    };
  }
};

const doPing = async () => {
  const url = `${ROOT_DHLLINK}/opsmgt/healthcheck`;
  const timeoutInSeconds = await getAppSettingsVal('gw_check.timeout');
  const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 3000;
  let response: any;
  try {
    response = await doFetch(url, {
      timeout: timeout,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + Base64.btoa(OAUTH_SECRET_USERNAME + ':' + OAUTH_SECRET_PASSWORD),
      },
    });
  } catch (e) {
    logi(`doPing error: `, e);
    return false;
  }
  logi(`doPing response.ok: `, !!(response && response.ok));
  return !!(response && response.ok);
};

export const isDeviceOnline = isWeb ? doPing : NativeModules.ParentNativeTools.isDeviceOnline;

export const getUniqueDeviceId = isWeb
  ? async () => 'Not implemented in Lite'
  : NativeModules.ParentNativeTools.getUniqueDeviceId;

export const isQa = () =>
  isWeb ? window.location.toString().includes('-qa') : DeviceInfo.getVersion().includes('-qa');
