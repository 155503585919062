// @ts-nocheckX
import {call, put} from 'redux-saga/effects';
import {attributeValueNames} from '../graphql/requestBodies';
import {GQL_UM_CORE_URL} from '../config';
import {apiCall} from '../utils/Oauth';
import {logi} from './logging';
import {setAttributeValueNamesAction} from '../flows/auth';
import {
  KEY_GROUP_ATTRIBUTES_VALUE_NAMES,
  KEY_GROUP_IDS_COUNTRIES_MAP,
  KEY_GROUP_IDS_FACILITIES_MAP,
  KEY_GROUPS_APPS_COUNTRIES_MAP,
  KEY_GROUPS_APPS_FACILITIES_MAP,
  loadValue,
  saveValue,
} from './sharedPrefs';
import {AssignedGroupAPI, AuthorizedItemAPI} from '../utils/types';

type AttributeValueRecord = {
  attributeId: string;
  valueId: string;
};

type ArrayPerId = {
  [index: string]: string[];
};

type ValuesPerAttribute = {
  attributeId: string;
  valueIds: string[];
};

type AttributeValuePerAppPerGroup = {
  [index: string]: ArrayPerId;
};

type GetAttributeValueNamesResponseType = {
  attributeValues: {
    attributeId: string;
    choices: {
      id: string;
      code: string;
      name: string;
    }[];
  }[];
};

const ATTRIBUTE_ID_COUNTRY = '12104';
const ATTRIBUTE_ID_FACILITY = '12102';

function collectAttributeValuesFromAuthorizations(assignedGroups: AssignedGroupAPI[], attributeId: string): ArrayPerId {
  const result: ArrayPerId = {};
  assignedGroups.forEach(ag => {
    const groupId = ag.group.id;
    const groupItem = result[groupId] || [];
    ag.group.authorizations.forEach(auth => {
      auth.attributes
        ?.filter(attr => attr.attribute.id === attributeId)
        .forEach(attr => {
          if (!groupItem.includes(attr.value)) {
            groupItem.push(attr.value);
          }
        });
    });
    result[groupId] = groupItem;
  });
  return result;
}

function collectAttributeValuesPerAppFromAuthorizations(
  assignedGroups: AssignedGroupAPI[],
  attributeId: string,
): AttributeValuePerAppPerGroup {
  const result: AttributeValuePerAppPerGroup = {};
  assignedGroups.forEach(ag => {
    const groupId = ag.group.id;
    const groupItem = result[groupId] || {};
    ag.group.authorizations
      .filter(it => it.role?.application)
      .forEach(auth => {
        const appLabel = auth.role?.application?.label || '?'; // or will not happen because of filter above
        const appItem = groupItem[appLabel] || [];
        auth.attributes
          ?.filter(attr => attr.attribute.id === attributeId)
          .forEach(attr => {
            if (!appItem.includes(attr.value)) {
              appItem.push(attr.value);
            }
          });
        groupItem[appLabel] = appItem;
      });
    result[groupId] = groupItem;
  });
  return result;
}

export function* getAttributeValueNames(assignedGroups: AssignedGroupAPI[], directAuthorizations: AuthorizedItemAPI[]) {
  const attributeValues: AttributeValueRecord[] = [];
  const groupsAndCountries = collectAttributeValuesFromAuthorizations(assignedGroups, ATTRIBUTE_ID_COUNTRY);
  const groupsAndFacilities = collectAttributeValuesFromAuthorizations(assignedGroups, ATTRIBUTE_ID_FACILITY);
  const groupsWithDirect = [...assignedGroups];
  // add direct authorizations as group id -1
  groupsWithDirect.push({group: {id: '-1', name: 'DIRECT', authorizations: directAuthorizations}, attributes: []});
  const countriesPerGroupAndApp = collectAttributeValuesPerAppFromAuthorizations(
    groupsWithDirect,
    ATTRIBUTE_ID_COUNTRY,
  );
  const facilitiesPerGroupAndApp = collectAttributeValuesPerAppFromAuthorizations(
    groupsWithDirect,
    ATTRIBUTE_ID_FACILITY,
  );

  for (const ag of groupsWithDirect) {
    ag?.group?.authorizations?.forEach(auths => {
      auths?.attributes?.forEach(attr => {
        if (attr.attribute.type === 'MULTICHOICE' || attr.attribute.type === 'DROPDOWN') {
          const attributeId = attr.attribute.id;
          const val = attr.value;
          attributeValues?.push({
            attributeId: attributeId,
            valueId: val,
          });
        }
      });
    });
  }

  const uniqueAttributeIds = [...new Set(attributeValues.map(it => it.attributeId))];
  const final: ValuesPerAttribute[] = uniqueAttributeIds.map(it => ({attributeId: it, valueIds: []}));
  for (const av of attributeValues) {
    const valueIds = final.find(i => i.attributeId === av.attributeId)?.valueIds || [];
    if (valueIds.indexOf(av.valueId) === -1) {
      valueIds.push(av.valueId);
    }
  }
  console.log(`getAttributeValueNames final: `, final);
  const body = attributeValueNames(final);
  let resp;
  try {
    const headers = {'Content-Type': 'application/json'};
    const options = {
      url: GQL_UM_CORE_URL,
      body: JSON.stringify(body),
      headers,
      method: 'POST',
      timeout: 10000,
    };
    // @ts-ignore
    resp = yield call(apiCall, options);
  } catch (e) {
    logi('getAttributeValueNames failed: ', e);
    return;
  }
  if (resp && resp.errors == null && resp.data != null && resp.data.attributeValues) {
    // console.log("getAttributeValueNames Response data", resp.data)
    const responseData: GetAttributeValueNamesResponseType = resp.data;
    yield put(setAttributeValueNamesAction(responseData.attributeValues));
    yield call(saveValue, KEY_GROUP_ATTRIBUTES_VALUE_NAMES, JSON.stringify(responseData.attributeValues));
    yield call(saveValue, KEY_GROUP_IDS_FACILITIES_MAP, JSON.stringify(groupsAndFacilities));
    yield call(saveValue, KEY_GROUPS_APPS_FACILITIES_MAP, JSON.stringify(facilitiesPerGroupAndApp));
    const countryCodeById: {[index: string]: string} = {};
    responseData.attributeValues
      .find(it => it.attributeId === ATTRIBUTE_ID_COUNTRY)
      ?.choices.forEach(choice => {
        countryCodeById[choice.id] = choice.code;
      });
    for (const groupId of Object.keys(groupsAndCountries)) {
      const countryIds = groupsAndCountries[groupId];
      groupsAndCountries[groupId] = countryIds.map(countryId => countryId);
    }
    for (const groupId of Object.keys(countriesPerGroupAndApp)) {
      const countryIdsPerApp = countriesPerGroupAndApp[groupId];
      for (const appLabel of Object.keys(countryIdsPerApp)) {
        const countryIds = countryIdsPerApp[appLabel];
        countryIdsPerApp[appLabel] = countryIds.map(countryId => countryId);
      }
    }
    yield call(saveValue, KEY_GROUP_IDS_COUNTRIES_MAP, JSON.stringify(groupsAndCountries));
    yield call(saveValue, KEY_GROUPS_APPS_COUNTRIES_MAP, JSON.stringify(countriesPerGroupAndApp));
  } else if (resp && resp.errors != null) {
    logi('getAttributeValueNames error response: ', JSON.stringify(resp));
  }
}

export const getCountriesByGroupAndApp = async (groupId: string, appLabel: string) => {
  const translateMapString = await loadValue(KEY_GROUPS_APPS_COUNTRIES_MAP);
  const translateMap: AttributeValuePerAppPerGroup = JSON.parse(translateMapString || '{}');
  const appItem = translateMap[groupId];
  return appItem ? appItem[appLabel] || [] : [];
};

export const getFacilitiesByGroupAndApp = async (groupId: string, appLabel: string) => {
  const translateMapString = await loadValue(KEY_GROUPS_APPS_FACILITIES_MAP);
  const translateMap: AttributeValuePerAppPerGroup = JSON.parse(translateMapString || '{}');
  const appItem = translateMap[groupId];
  return appItem ? appItem[appLabel] || [] : [];
};
