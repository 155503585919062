import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './languages/en.json';

// Resources: https://www.i18next.com/overview/getting-started#basic-sample, https://react.i18next.com/latest/i18next-instance
// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    //lowerCaseLng: true,
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
    resources: {
      en: {translation: en},
      'en-US': {translation: en},
    },
    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
        react: {
          bindI18n: 'languageChanged',
          bindI18nStore: '',
          transEmptyNodeValue: '',
          transSupportBasicHtmlNodes: true,
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
          useSuspense: true,
        }
        */
  });

export default {
  t: (key: string | Array<string>, params?: Record<string, string>): string => {
    return i18n.t(key, params);
  },
  i18n: i18n,
};
